import React, { memo } from "react";
import ExportCSVButton from "../../../shared/ExportCSVButton";
import { flattenArray, formatExcelNumberString } from "../../../../lib/utils";
import moment from "moment";

const exportTypes = {
  Basic: "Basic",
  Full: "Full",
};

function RaffleEnrollmentActions(props) {
  const {
    filters: { enrolled },
    getEnrollmentsForExport,
    raffleFeesPaid,
    raffleTitle,
  } = props;

  const getExportHeaders = (exportType, data) => {
    const maxEmailRecipientsCount = Math.max(
      ...data.map((d) => d.emailRecipients?.length || 0)
    );
    return [
      { label: "Shliach ID", value: "shliachID" },
      { label: "Shliach First Name", value: "shliachFirstName" },
      { label: "Shliach Last Name", value: "shliachLastName" },
      { label: "Shliach Email", value: "shliachEmail" },
      { label: "Shliach Cell Phone", value: "shliachCell" },
      ...(!enrolled
        ? [{ label: "Shliach Category", value: "shliachCategoryDisplay" }]
        : []),
      { label: "Shlucha ID", value: "shluchaID" },
      { label: "Shlucha First Name", value: "shluchaFirstName" },
      { label: "Shlucha Last Name", value: "shluchaLastName" },
      { label: "Shlucha Email", value: "shluchaEmail" },
      { label: "Shlucha Cell Phone", value: "shluchaCell" },
      ...(!enrolled
        ? [{ label: "Shlucha Category", value: "shluchaCategoryDisplay" }]
        : []),
      { label: "Chabad House ID", value: "chabadHouseID" },
      { label: "Chabad House", value: "chabadHouseName" },
      ...(!enrolled || exportType === exportTypes.Full
        ? [
            { label: "Chabad House Address 1", value: "chabadHouseAddress1" },
            { label: "Chabad House Address 2", value: "chabadHouseAddress2" },
            { label: "Chabad House City", value: "chabadHouseCity" },
            { label: "Chabad House State", value: "chabadHouseState" },
            {
              label: "Chabad House Zip",
              value: ({ chabadHouseZip }) =>
                formatExcelNumberString(chabadHouseZip),
            },
            { label: "Chabad House Country", value: "chabadHouseCountry" },
          ]
        : []),
      ...(enrolled && exportType === exportTypes.Basic
        ? [
            { label: "Tickets Sold", value: "totalTicketsSold" },
            {
              label: `COCI Owe${raffleFeesPaid ? "d" : "s"}`,
              value: "cociOwes",
            },
            {
              label: `Shliach Owe${raffleFeesPaid ? "d" : "s"}`,
              value: "chabadHouseOwes",
            },
            { label: "Currency", value: "currencyCode" },
            {
              label: "Enrollment Date",
              value: (se) =>
                se.dateEnrolledOn
                  ? moment(se.dateEnrolledOn).format("MM/DD/YYYY")
                  : "",
            },
            { label: "Teams", value: "referrersCount", default: 0 },
            {
              label: "Total Tickets Sold by Teams",
              value: "ticketsSoldByReferrers",
              default: 0,
            },
          ]
        : []),
      ...(exportType === exportTypes.Full
        ? [
            { label: "Campus ID", value: "campusID" },
            { label: "Campus", value: "campusName" },
            {
              label: "Tickets Sold on Raffle site",
              value: "onlineTicketsSold",
              default: 0,
            },
            {
              label: "Tickets Not Sold on Raffle site",
              value: "offlineTicketsSold",
              default: 0,
            },
            {
              label: "CC and Paypal Tickets Sold",
              value: "ccCount",
              default: 0,
            },
            {
              label: "Non-CC and Non-Paypal Tickets Sold",
              value: "nonCcCount",
              default: 0,
            },
            { label: "Total Raised", value: "totalRaised" },
            { label: "CC and Paypal Total", value: "ccTotal", default: 0 },
            {
              label: "Total CC and Paypal Fees",
              value: "totalCcFees",
              default: 0,
            },
            {
              label: "Total Donated CC and Paypal Fees",
              value: "totalProcessingFeeDonorAmountRaised",
              default: 0,
            },
            { label: "Total COCI Fees", value: "totalCociFees", default: 0 },
            {
              label: `Shliach Owe${raffleFeesPaid ? "d" : "s"}`,
              value: "chabadHouseOwes",
              default: 0,
            },
            {
              label: `COCI Owe${raffleFeesPaid ? "d" : "s"}`,
              value: "cociOwes",
            },
            { label: "Currency", value: "currencyCode" },
            {
              label: "Enrollment Date",
              value: (se) =>
                se.dateEnrolledOn
                  ? moment(se.dateEnrolledOn).format("MM/DD/YYYY")
                  : "",
            },
            { label: "Teams", value: "referrersCount", default: 0 },
            {
              label: "Total Tickets Sold by Teams",
              value: "ticketsSoldByReferrers",
              default: 0,
            },
          ]
        : []),
      ...flattenArray(
        Array.from({ length: maxEmailRecipientsCount }, (_, index) => [
          {
            label: `Recipient ${index + 1} First Name`,
            value: ({ emailRecipients }) =>
              (emailRecipients && emailRecipients[index]?.firstName) || "",
          },
          {
            label: `Recipient ${index + 1} Last Name`,
            value: ({ emailRecipients }) =>
              (emailRecipients && emailRecipients[index]?.lastName) || "",
          },
          {
            label: `Recipient ${index + 1} Email`,
            value: ({ emailRecipients }) =>
              (emailRecipients && emailRecipients[index]?.email) || "",
          },
        ])
      ),
    ];
  };

  const getEnrollmentsDataForExport = async (exportType) => {
    return await getEnrollmentsForExport(exportType);
  };

  return (
    <div className="dropdown-container">
      <button className="btn btn-accent flex flex-align-center">
        CSV Export
        <i className="material-icons ml-8">arrow_drop_down</i>
      </button>
      <div className="dropdown btn-dropdown csv-export-dropdown">
        <ExportCSVButton
          className="small-text mb-0 link-text-secondary"
          fileName={`${(raffleTitle || "Grand Draw").replace(/ /g, "_")}_${
            enrolled ? "Enrollments" : "Unenrolled_Shluchim"
          }`}
          getExportData={() => getEnrollmentsDataForExport(exportTypes.Basic)}
          getHeaders={(data) => getExportHeaders(exportTypes.Basic, data)}
          title="Export"
        />
        {!!enrolled && (
          <ExportCSVButton
            className="small-text mb-0 mt-16 link-text-secondary"
            fileName={`${(raffleTitle || "Grand Draw").replace(
              / /g,
              "_"
            )}_Enrollments_QB`}
            getExportData={() => getEnrollmentsDataForExport(exportTypes.Full)}
            getHeaders={(data) => getExportHeaders(exportTypes.Full, data)}
            title="QuickBooks Export"
          />
        )}
      </div>
    </div>
  );
}

export default memo(RaffleEnrollmentActions);
