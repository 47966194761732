import React from "react";
import ConfirmationModal from "../../shared/ConfirmationModal";
import PaginatedTable from "../../shared/PaginatedTable";
import AddLocationModal from "./AddLocationModal";
import LocationDetailsModal from "./location/LocationDetailsModal";
import LocationsTableActions from "./LocationsTableActions";
import LocationsTableFilters from "./LocationsTableFilters";
import LocationsTableHeader from "./LocationsTableHeader";
import LocationsTableRow from "./LocationsTableRow";
import SinaiScholarsImportModal from "../import/SinaiScholarsImportModal";

import EduApi from "../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import { EduProgramTypes } from "../EduConsts";
import axios from "axios";

export default class LocationsTable extends React.PureComponent {
  state = {
    errorMessage: "",
    filters: {
      keyword: "",
      subscriptionStatus: "",
    },
    loading: false,
    locations: [],
    page: 1,
    removeLocationErrorMessage: null,
    removeLocationLoading: false,
    results: 12,
    selectedLocation: null,
    showAddLocationModal: false,
    showImportLocationsModal: false,
    showLocationDetailsModal: false,
    showRemoveLocationModal: false,
    sortBy: "shliachName",
    success: true,
    totalLocations: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterLocations = (filters) => {
    this.setState({ filters }, () => {
      this.getLocations();
    });
  };

  getLocations = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        EduApi.getEduChabadHouses(
          this.apiSignal.token,
          this.props.eduProgram.id,
          page,
          results,
          sortBy,
          this.state.filters
        )
          .then(({ chabadHouses, totalChabadHouses }) => {
            this.setState({
              loading: false,
              locations: chabadHouses,
              totalLocations: totalChabadHouses,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                locations: [],
                success: false,
                totalLocations: 0,
              });
            }
          });
      }
    );
  };

  getLocationsForExport = async () => {
    const { sortBy, filters } = this.state;

    const locationsForExport = await EduApi.getEduChabadHouses(
      this.apiSignal.token,
      this.props.eduProgram.id,
      undefined,
      undefined,
      sortBy,
      filters,
      true
    );

    return locationsForExport.chabadHouses;
  };

  addLocation = async (chabadHouseId) => {
    const submitLocationResponse = {};

    try {
      await EduApi.submitEduChabadHouse(this.apiSignal.token, {
        chabadHouseId,
        eduProgramId: this.props.eduProgram.id,
      });
      submitLocationResponse.success = true;

      //reload locations
      this.getLocations();
      //refresh program details
      this.props.refreshEduProgram();
    } catch (err) {
      if (!axios.isCancel(err)) {
        submitLocationResponse.error = err;
        submitLocationResponse.errorMessage = ApiCallErrorMessageHandler(err);
      }
    }

    return submitLocationResponse;
  };

  removeLocation = async () => {
    this.setState({
      removeLocationErrorMessage: "",
      removeLocationLoading: true,
    });

    try {
      //get location details
      const chabadHouseDetails = await EduApi.getEduChabadHouse(
        this.apiSignal.token,
        this.state.selectedLocation.id
      );

      //submit deactivated location
      await EduApi.submitEduChabadHouse(this.apiSignal.token, {
        ...chabadHouseDetails,
        isActive: false,
      });

      //reload locations:
      const { page, results, sortBy } = this.state;
      this.getLocations(page, results, sortBy);
      this.setState({
        removeLocationLoading: false,
        selectedLocation: null,
        showRemoveLocationModal: false,
      });
      //refresh program details
      this.props.refreshEduProgram();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          removeLocationErrorMessage: ApiCallErrorMessageHandler(err),
          removeLocationLoading: false,
        });
      }
    }
  };

  toggleViewLocationDetails = (show, location = null) => {
    this.setState({
      selectedLocation: location,
      showLocationDetailsModal: show,
    });
  };

  render() {
    const {
      chabadHouses,
      eduProgram,
      mobileMode,
      refreshEduProgram,
      viewOnly,
    } = this.props;

    const {
      loading,
      locations,
      page,
      removeLocationErrorMessage,
      removeLocationLoading,
      results,
      selectedLocation,
      showAddLocationModal,
      showImportLocationsModal,
      showLocationDetailsModal,
      showRemoveLocationModal,
      sortBy,
      success,
      totalLocations,
    } = this.state;

    return (
      <div>
        <div className="flex flex-justify-space flex-align-center">
          <p className="xxl-text fw-500">Locations</p>
          <LocationsTableActions
            eduProgram={eduProgram}
            getLocationsForExport={this.getLocationsForExport}
            onAddLocation={() => this.setState({ showAddLocationModal: true })}
            onImportLocations={() =>
              this.setState({ showImportLocationsModal: true })
            }
            viewOnly={viewOnly}
          />
        </div>
        <LocationsTableFilters
          applyFilters={this.filterLocations}
          eduProgramType={eduProgram.type}
        />
        <div
          className={`courses-table ${
            eduProgram.type === EduProgramTypes.JewishU
              ? "jewish-u-locations-table"
              : eduProgram.type === EduProgramTypes.YourIsrael
              ? "your-israel-locations-table"
              : ""
          }`}
        >
          <PaginatedTable
            loading={loading}
            loadData={this.getLocations}
            mobileMode={mobileMode}
            page={page}
            records={locations}
            renderHeader={() => (
              <LocationsTableHeader eduProgramType={eduProgram.type} />
            )}
            renderRow={(location, index) => (
              <LocationsTableRow
                eduProgramType={eduProgram.type}
                key={index}
                location={location}
                onRemoveLocation={() =>
                  this.setState({
                    selectedLocation: location,
                    showRemoveLocationModal: true,
                  })
                }
                onViewLocationDetails={() =>
                  this.toggleViewLocationDetails(true, location)
                }
                viewOnly={viewOnly}
              />
            )}
            results={results}
            showResultsCount={true}
            sortBy={sortBy}
            success={success}
            totalCount={totalLocations}
          />
        </div>

        <AddLocationModal
          chabadHouses={chabadHouses}
          close={() => this.setState({ showAddLocationModal: false })}
          show={showAddLocationModal}
          submit={this.addLocation}
        />
        <LocationDetailsModal
          close={() => this.toggleViewLocationDetails(false)}
          locationId={selectedLocation && selectedLocation.id}
          show={showLocationDetailsModal}
        />
        <ConfirmationModal
          cancel={() =>
            this.setState({
              removeLocationErrorMessage: "",
              selectedLocation: null,
              showRemoveLocationModal: false,
            })
          }
          confirm={this.removeLocation}
          errorMessage={removeLocationErrorMessage}
          loading={removeLocationLoading}
          message={`Are you sure you'd like to remove ${
            selectedLocation
              ? selectedLocation.chabadHouseName
              : "this location"
          }'s access to ${eduProgram.name}?`}
          show={showRemoveLocationModal}
        />
        <SinaiScholarsImportModal
          close={() => this.setState({ showImportLocationsModal: false })}
          reloadEduProgramDetails={() => {
            //reload locations
            this.getLocations();
            //refresh program details
            refreshEduProgram();
          }}
          show={showImportLocationsModal}
          type="Locations"
        />
      </div>
    );
  }
}
