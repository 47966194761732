import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import Loader from "../../../../../../common/Loader";
import NumberFormat from "react-number-format";

import TripEventsApi from "../../../../../../../services/resources/TripEventsApi";
import { ApiCallErrorMessageHandler } from "../../../../../../../lib/coc-common-scripts";
import { formatCurrency } from "../../../../../../../lib";
import { notify } from "react-notify-toast";
import axios from "axios";

function RegistrationSplitFeeRefunds(props) {
  const {
    onRegistrationUpdated,
    registration: { id: registrationId, total, wasPaymentProcessed },
  } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [cociRefundAmount, setCociRefundAmount] = useState("");
  const [shliachRefundAmount, setShliachRefundAmount] = useState("");
  const [availableRefundAmounts, setAvailableRefundAmounts] = useState();

  const [showRefundModal, setShowRefundModal] = useState(false);

  const apiSignalRef = useRef(axios.CancelToken.source());

  useEffect(() => {
    const _apiSignal = apiSignalRef.current;
    return () => _apiSignal.cancel();
  }, []);

  const onClickRefund = useCallback(async () => {
    // get available refund details
    setErrorMessage("");
    setShowRefundModal(true);

    try {
      const { cociAvailableRefundAmount, shliachAvailableRefundAmount } =
        await TripEventsApi.getTripRegistrationAvailableRefunds(
          apiSignalRef.current.token,
          registrationId
        );

      setAvailableRefundAmounts({
        coci: cociAvailableRefundAmount,
        shliach: shliachAvailableRefundAmount,
      });
    } catch (e) {
      setErrorMessage(ApiCallErrorMessageHandler(e));
    }
  }, [registrationId]);

  const onConfirmRefund = useCallback(async () => {
    if (!shliachRefundAmount && !cociRefundAmount) {
      setErrorMessage("Please enter a refund amount.");
      return;
    }

    setErrorMessage("");
    setLoading(true);

    try {
      const updatedRegistration = await TripEventsApi.issuePartialRefund(
        apiSignalRef.current.token,
        registrationId,
        {
          cociRefundAmount: cociRefundAmount || 0,
          shliachRefundAmount: shliachRefundAmount || 0,
        }
      );

      onRegistrationUpdated(updatedRegistration);

      setShowRefundModal(false);

      setCociRefundAmount("");
      setShliachRefundAmount("");
      setAvailableRefundAmounts();

      const successMessage = `Your refund of $${formatCurrency(
        shliachRefundAmount + cociRefundAmount
      )} has been issued`;
      notify.show(successMessage, "success");
    } catch (e) {
      setErrorMessage(ApiCallErrorMessageHandler(e));
    }

    setLoading(false);
  }, [
    cociRefundAmount,
    onRegistrationUpdated,
    registrationId,
    shliachRefundAmount,
  ]);

  const onCancelRefund = useCallback(() => {
    setErrorMessage("");
    setCociRefundAmount("");
    setShliachRefundAmount("");
    setAvailableRefundAmounts();
    setShowRefundModal(false);
  }, []);

  const disabled = !(wasPaymentProcessed && total > 0);

  return (
    <>
      <p className="fw-700 mb-8">Partial Refunds</p>
      <p className="mb-16">
        Issue a partial or full refund without changing the status.
      </p>
      <div className="tooltip-container">
        <button
          className="btn custom-btn btn-accent btn-medium uppercase-text"
          disabled={disabled}
          onClick={onClickRefund}
        >
          Issue Partial Refund
        </button>
        {disabled && (
          <span className="tooltip">
            {!wasPaymentProcessed
              ? "Payment was not processed"
              : "Total paid is $0"}
          </span>
        )}
      </div>
      <Modal isOpen={showRefundModal} className="modal-container">
        <div className="card small-modal trip-modal">
          <p className="large-text mb-24">Refund Student</p>
          {!availableRefundAmounts ? (
            errorMessage ? (
              <div className="error-text">{errorMessage}</div>
            ) : (
              <Loader />
            )
          ) : (
            <>
              <div className="partial-refund-inputs">
                <div className="two-column-grid">
                  <div>From COCI</div>
                  <div>
                    <NumberFormat
                      allowNegative={false}
                      className="custom-input dollar-input"
                      decimalScale={2}
                      isAllowed={({ floatValue }) =>
                        !(floatValue > availableRefundAmounts.coci)
                      }
                      name="cociRefundAmount"
                      onValueChange={({ floatValue }) =>
                        setCociRefundAmount(floatValue || "")
                      }
                      thousandSeparator={true}
                      value={cociRefundAmount}
                    />
                    <div className="small-text accent-text text-right mr-8">
                      max ${formatCurrency(availableRefundAmounts.coci)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="partial-refund-inputs">
                <div className="two-column-grid pricing-two-column-grid">
                  <div>From Shliach</div>
                  <div>
                    <NumberFormat
                      allowNegative={false}
                      className="custom-input dollar-input"
                      isAllowed={({ floatValue }) =>
                        !(floatValue > availableRefundAmounts.shliach)
                      }
                      name="shliachRefundAmount"
                      decimalScale={2}
                      onValueChange={({ floatValue }) =>
                        setShliachRefundAmount(floatValue || "")
                      }
                      thousandSeparator={true}
                      value={shliachRefundAmount}
                    />
                    <div className="small-text accent-text text-right mr-8">
                      max ${formatCurrency(availableRefundAmounts.shliach)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-btns">
                <button
                  className="link-text uppercase-text"
                  disabled={loading}
                  onClick={onCancelRefund}
                >
                  Cancel
                </button>
                <button
                  className={`link-text uppercase-text ml-24${
                    loading ? " disabled" : ""
                  }`}
                  disabled={loading}
                  onClick={onConfirmRefund}
                >
                  Refund{loading ? "ing..." : ""}
                </button>
              </div>
              {!loading && errorMessage && (
                <div className="text-right error-text mt-4">{errorMessage}</div>
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default React.memo(RegistrationSplitFeeRefunds);
