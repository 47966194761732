import React from "react";
import { EduProgramTypes } from "../EduConsts";
import { formatFullName } from "../../../lib";

export default class LocationsTableRow extends React.PureComponent {
  render() {
    const {
      eduProgramType,
      location: {
        campusName,
        chabadHouseName,
        isExpired,
        isPending,
        latestCourseScheduleCourseName,
        latestCourseScheduleSemester,
        latestCourseScheduleYear,
        shliachFirstName,
        shliachLastName,
        shluchaFirstName,
      },
      onRemoveLocation,
      onViewLocationDetails,
      viewOnly,
    } = this.props;

    return (
      <div
        className="courses-locations-table-row"
        onClick={onViewLocationDetails}
      >
        <p>{formatFullName(shliachFirstName, shliachLastName)}</p>
        <p>{formatFullName(shluchaFirstName, shliachLastName)}</p>
        <p>{chabadHouseName}</p>
        <p>{campusName}</p>
        <div>
          {latestCourseScheduleCourseName &&
            (eduProgramType === EduProgramTypes.YourIsrael ? (
              latestCourseScheduleYear
            ) : (
              <React.Fragment>
                <p>{latestCourseScheduleCourseName}</p>
                <p>
                  {latestCourseScheduleSemester} {latestCourseScheduleYear}
                </p>
              </React.Fragment>
            ))}
        </div>
        {eduProgramType === EduProgramTypes.JewishU && (
          <p>{isPending ? "" : isExpired ? "Expired" : "Active"}</p>
        )}
        <p>
          {!viewOnly && (
            <i
              className="link-text-secondary material-icons medium-text"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveLocation();
              }}
              style={{ padding: "8px" }}
            >
              delete
            </i>
          )}
        </p>
      </div>
    );
  }
}
