import React from "react";
import RaffleOrders from "../RaffleDetails/Orders/RaffleOrders";

export default class RaffleEnrollmentOrders extends React.PureComponent {
  render() {
    const {
      enrollment,
      getEnrollmentMetricsAndSales,
      location,
      mobileMode,
      raffle: {
        cociCcProcessingFeePercentage,
        id: raffleId,
        isActive,
        isOpenForManualTicketEntry,
        isPastDrawingDate,
        raffleTitle,
        startDate,
        endDate,
      },
      readOnlyAccess,
      systemCountries,
      systemRaffleDonorAssociations,
    } = this.props;
    return (
      <RaffleOrders
        cociCcProcessingFeePercentage={cociCcProcessingFeePercentage}
        enrollment={enrollment}
        enrollmentId={enrollment.id}
        getEnrollmentMetricsAndSales={getEnrollmentMetricsAndSales}
        location={location}
        mobileMode={mobileMode}
        raffleId={raffleId}
        raffleIsActive={isActive}
        raffleIsOpenForManualTicketEntry={isOpenForManualTicketEntry}
        raffleIsPastDrawingDate={isPastDrawingDate}
        raffleTitle={raffleTitle}
        readOnlyAccess={readOnlyAccess}
        systemCountries={systemCountries}
        systemRaffleDonorAssociations={systemRaffleDonorAssociations}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }
}
