import React from "react";
import ExportCSVButton from "../../../shared/ExportCSVButton";
import StudentsBulkStatusChangeModal from "./StudentsBulkStatusChangeModal";
import { getTravelTripRegistrationStatusWorkflowDisplay } from "../../TripEventLogic";
import { TripTypes } from "../../TripEventConsts";
import moment from "moment";

export default class StudentsActions extends React.PureComponent {
  state = { showBulkChangeStatusModal: false };

  exportTypes = {
    Basic: "Basic",
    Full: "Full",
  };

  getExportHeaders = (exportType) => {
    const tourScheduleColumns = [];

    const {
      tripEvent: {
        hasParentLetters,
        isMarketingConsentRequired,
        isPassportIdRequired,
        isProofOfVaccinationRequired,
        isTravelTrip,
        tours,
        type,
      },
    } = this.props;

    tours.forEach((tour) => {
      tour.schedules.forEach((tourSchedule) => {
        const scheduleName = `${tour.name} - ${tourSchedule.name}`;
        const scheduleColumns = [
          {
            label: scheduleName,
            value: ({ tourScheduleIDs }) =>
              tourScheduleIDs && tourScheduleIDs.indexOf(tourSchedule.id) >= 0
                ? "Y"
                : "N",
          },
          //Skipping tours transportation data for now
          // {
          //   label: `Transportation To ${scheduleName}`,
          //   value: ({ transportationToTourScheduleIDs }) =>
          //     transportationToTourScheduleIDs &&
          //     transportationToTourScheduleIDs.indexOf(tourSchedule.id) >= 0
          //       ? "Y"
          //       : "N"
          // },
          // {
          //   label: `Transportation From ${scheduleName}`,
          //   value: ({ transportationFromTourScheduleIDs }) =>
          //     transportationFromTourScheduleIDs &&
          //     transportationFromTourScheduleIDs.indexOf(tourSchedule.id) >= 0
          //       ? "Y"
          //       : "N"
          // }
        ];
        tourScheduleColumns.push(...scheduleColumns);
      });
    });

    const headers = [
      { label: "Student ID", value: "studentID" },
      { label: "First Name", value: "studentFirstName" },
      { label: "Last Name", value: "studentLastName" },
      { label: "Email", value: "studentEmail" },
      { label: "Cell Phone", value: "studentCell" },
      {
        label: "Birthday",
        value: ({ dob }) => (dob ? moment(dob).format("MM/DD/YYYY") : ""),
      },
      { label: "Age", value: "ageAtTrip" },
      { label: "Gender", value: "gender" },
      {
        label: "Address - State",
        value: "studentState",
        fullExport: true,
      },
      {
        label: "Allergies",
        value: ({ allergiesDisplay }) => allergiesDisplay?.join(", "),
        fullExport: true,
      },
      { label: "Dietary Notes", value: "dietaryNotes", fullExport: true },
      { label: "Demographic", value: "classDisplay", fullExport: true },
      {
        label: "Graduation Year",
        value: "graduationYear",
        fullExport: true,
      },
      { label: "Hebrew Name", value: "hebrewName", fullExport: true },
      {
        label: "Jewish",
        value: ({ isJewish }) =>
          isJewish ? "Y" : isJewish === false ? "N" : "",
        fullExport: true,
      },
      {
        label: "Mother's Name",
        value: "motherName",
        fullExport: true,
      },
      {
        label: "Mother's Jewish Background",
        value: "motherBackgroundDisplay",
        fullExport: true,
      },
      {
        label: "Mother's Email",
        value: "motherEmail",
        fullExport: true,
      },
      {
        label: "Mother's Phone",
        value: "motherPhone",
        fullExport: true,
      },
      {
        label: "Father's Name",
        value: "fatherName",
        fullExport: true,
      },
      {
        label: "Father's Jewish Background",
        value: "fatherBackgroundDisplay",
        fullExport: true,
      },
      {
        label: "Father's Email",
        value: "fatherEmail",
        fullExport: true,
      },
      {
        label: "Father's Phone",
        value: "fatherPhone",
        fullExport: true,
      },
      {
        label: "Shirt Size",
        value: "tShirtSizeDisplay",
        fullExport: true,
      },
      { label: "Shliach ID", value: "shliachID" },
      { label: "Shliach First Name", value: "shliachFirstName" },
      { label: "Shliach Last Name", value: "shliachLastName" },
      { label: "Shliach Email", value: "shliachEmail" },
      { label: "Shliach Phone", value: "shliachCell" },
      {
        label: "Chabad House Address - State",
        value: "chabadHouseState",
        fullExport: true,
      },
      { label: "Campus ID", value: "campusID" },
      { label: "Campus", value: "campusName" },
      {
        label: "Status",
        value: (registration) =>
          isTravelTrip
            ? getTravelTripRegistrationStatusWorkflowDisplay(registration)
            : registration.statusDisplay,
      },
      {
        label: "Status Last Changed",
        value: ({ statusWorkflowUpdatedOn }) =>
          statusWorkflowUpdatedOn
            ? moment(statusWorkflowUpdatedOn).format("MM/DD/YYYY")
            : "",
      },
      {
        label: "Date Applied",
        value: ({ registrationDate }) =>
          registrationDate ? moment(registrationDate).format("MM/DD/YYYY") : "",
      },
      {
        label: "Date Accepted/Rejected",
        value: ({ acceptedRejectedDate }) =>
          acceptedRejectedDate
            ? moment(acceptedRejectedDate).format("MM/DD/YYYY")
            : "",
      },
      {
        label: "Date Cancelled",
        value: ({ cancelledDate }) =>
          cancelledDate ? moment(cancelledDate).format("MM/DD/YYYY") : "",
      },
      {
        label: "Latest Cancellation",
        value: ({ latestCancellationDate }) =>
          latestCancellationDate
            ? moment(latestCancellationDate).format("MM/DD/YYYY hh:mm A")
            : "",
        fullExport: true,
      },
      ...(!isTravelTrip
        ? [
            {
              label: "Checked In",
              value: ({ checkedInDate }) => (!!checkedInDate ? "Y" : "N"),
              fullExport: true,
            },
            {
              label: "Check In Time",
              value: ({ checkedInDate }) =>
                checkedInDate
                  ? moment(checkedInDate).format("MM/DD/YYYY hh:mm A")
                  : "",
              fullExport: true,
            },
          ]
        : []),
      { label: "Track", value: "trackName" },
      ...tourScheduleColumns,
      { label: "Payment Total", value: "total" },
      {
        label: "Applied Promo Codes",
        value: ({ promoCodesApplied }) => promoCodesApplied?.join(", "),
      },
      {
        label: "Applied Credits",
        value: ({ rewardCreditsApplied }) => rewardCreditsApplied || "",
      },
      {
        label: "Trip Fee Type (Early Bird or Regular)",
        value: ({ wasEarlyBird }) => (wasEarlyBird ? "Early Bird" : "Regular"),
      },
      {
        label: "Trip Fee",
        value: "tripFee",
        fullExport: true,
      },
      ...(isTravelTrip
        ? [
            {
              label: "Deposit Amount",
              value: "depositAmount",
              fullExport: true,
            },
          ]
        : []),
      ...(isProofOfVaccinationRequired
        ? [
            {
              label: "Has Proof Of Vaccination",
              value: ({ hasProofOfVaccination }) =>
                hasProofOfVaccination ? "Y" : "N",
              fullExport: true,
            },
          ]
        : []),
      ...(isTravelTrip
        ? [
            {
              label: "Has Medical Conditions",
              value: ({ hasMedicalCondition }) =>
                hasMedicalCondition ? "Y" : "N",
              fullExport: true,
            },
            {
              label: "Medical Conditions Details",
              value: "medicalConditionDescription",
              fullExport: true,
            },
            {
              label: "Is Taking Medication",
              value: ({ isTakingMedication }) =>
                isTakingMedication ? "Y" : "N",
              fullExport: true,
            },
            {
              label: "Medication Details",
              value: "medicationDescription",
              fullExport: true,
            },
            {
              label: "Emergency Contact 1 Name",
              value: ({ emergencyContacts }) =>
                emergencyContacts[0] ? emergencyContacts[0].name : "",
              fullExport: true,
            },
            {
              label: "Emergency Contact 1 Email",
              value: ({ emergencyContacts }) =>
                emergencyContacts[0] ? emergencyContacts[0].email : "",
              fullExport: true,
            },
            {
              label: "Emergency Contact 1 Phone",
              value: ({ emergencyContacts }) =>
                emergencyContacts[0] ? emergencyContacts[0].phoneNumber : "",
              fullExport: true,
            },
            {
              label: "Emergency Contact 1 Relationship",
              value: ({ emergencyContacts }) =>
                emergencyContacts[0] ? emergencyContacts[0].relationship : "",
              fullExport: true,
            },
            {
              label: "Emergency Contact 2 Name",
              value: ({ emergencyContacts }) =>
                emergencyContacts[1] ? emergencyContacts[1].name : "",
              fullExport: true,
            },
            {
              label: "Emergency Contact 2 Email",
              value: ({ emergencyContacts }) =>
                emergencyContacts[1] ? emergencyContacts[1].email : "",
              fullExport: true,
            },
            {
              label: "Emergency Contact 2 Phone",
              value: ({ emergencyContacts }) =>
                emergencyContacts[1] ? emergencyContacts[1].phoneNumber : "",
              fullExport: true,
            },
            {
              label: "Emergency Contact 2 Relationship",
              value: ({ emergencyContacts }) =>
                emergencyContacts[1] ? emergencyContacts[1].relationship : "",
              fullExport: true,
            },
            ...(type !== TripTypes.Conference
              ? [
                  {
                    label: "Legal First Name",
                    value: "legalFirstName",
                    fullExport: true,
                  },
                  {
                    label: "Legal Middle Name",
                    value: "legalMiddleName",
                    fullExport: true,
                  },
                  {
                    label: "Legal Last Name",
                    value: "legalLastName",
                    fullExport: true,
                  },
                  {
                    label: `${isPassportIdRequired ? "Passport" : "ID"} Number`,
                    value: "officialIdNumber",
                    fullExport: true,
                  },
                  {
                    label: `${
                      isPassportIdRequired ? "Passport" : "ID"
                    } Expiration`,
                    value: ({ officialIdExpirationDate }) =>
                      officialIdExpirationDate
                        ? moment(officialIdExpirationDate).format("MM/DD/YYYY")
                        : "",
                    fullExport: true,
                  },
                  {
                    label: "Known Traveler #",
                    value: "knownTravelerNumber",
                    fullExport: true,
                  },
                ]
              : []),
            {
              label: "Does Shliach know this student",
              value: "shliachAcquaintanceLevelDisplay",
              fullExport: true,
            },
            {
              label: "Was Shliach aware of signup",
              value: ({
                shliachHadPriorKnowledgeOfStudentRegistration,
                wasRecommendationSubmitted,
              }) =>
                wasRecommendationSubmitted
                  ? shliachHadPriorKnowledgeOfStudentRegistration
                    ? "Y"
                    : "N"
                  : "",
              fullExport: true,
            },
            {
              label: "Shliach relationship with student",
              value: "shliachRelationshipWithStudent",
              fullExport: true,
            },
            {
              label: "Shliach expected growth from student as result of trip",
              value: "shliachExpectedStudentGrowth",
              fullExport: true,
            },
            {
              label: "Does Shliach recommend student",
              value: "recommendationStatusDisplay",
              fullExport: true,
            },
            {
              label: "Shliach Recommendation comments",
              value: ({ recommendationComments }) =>
                recommendationComments?.replace("\n", " "), // removing line breaks bec they are exported as text
              fullExport: true,
            },
            {
              label: "Admin notes",
              value: ({ adminNotes }) => adminNotes?.replaceAll("\n", " "), // removing line breaks bec they are exported as text
              fullExport: true,
            },
            {
              label: "Bus #",
              value: "busNumber",
              fullExport: true,
            },
            {
              label: "Flight Information",
              value: "flightDetails",
              fullExport: true,
            },
            ...(hasParentLetters
              ? [
                  {
                    label: "Parent letter(s) submited",
                    value: ({ wasParentLetterSubmitted }) =>
                      wasParentLetterSubmitted ? "Y" : "N",
                    fullExport: true,
                  },
                ]
              : []),
            {
              label: "Survey submitted",
              value: ({ wasSurveySubmitted }) =>
                wasSurveySubmitted ? "Y" : "N",
              fullExport: true,
            },
            {
              label: "Deposit status",
              value: ({ wasDepositRefunded }) =>
                wasDepositRefunded ? "Refunded" : "",
              fullExport: true,
            },
          ]
        : []),
      ...(isMarketingConsentRequired
        ? [
            {
              label: "COC Funding partners consent to Market",
              value: ({ didConsentToMosaicMarketingContact }) =>
                didConsentToMosaicMarketingContact
                  ? "Y"
                  : didConsentToMosaicMarketingContact === false
                  ? "N"
                  : "",
              fullExport: true,
            },
          ]
        : []),
    ];

    return exportType === this.exportTypes.Full
      ? headers
      : headers.filter((h) => !h.fullExport);
  };

  render() {
    const { showBulkChangeStatusModal } = this.state;
    const {
      allStatuses,
      allWorkflowSteps,
      getStudentsForExport,
      getStudentsSelection,
      onStatusUpdate,
      selectedStudents,
      showBulkStatusUpdateButton,
      showMoreTableColumns,
      toggleShowMoreTableColumns,
      tripEvent: { isTravelTrip, programScheduleName, tours, tracks },
    } = this.props;

    const showShowMoreButton = tours.length > 0 || tracks.length > 1;

    return (
      <div className="flex flex-align-center">
        {showBulkStatusUpdateButton && (
          <>
            <button
              className="custom-btn btn uppercase-text ml-16"
              disabled={
                !selectedStudents.all && !selectedStudents.selection.length
              }
              onClick={() => this.setState({ showBulkChangeStatusModal: true })}
            >
              Change Status
            </button>
            {showBulkChangeStatusModal && (
              <StudentsBulkStatusChangeModal
                allStatuses={allStatuses}
                allWorkflowSteps={allWorkflowSteps}
                close={() =>
                  this.setState({ showBulkChangeStatusModal: false })
                }
                isTravelTrip={isTravelTrip}
                programScheduleName={programScheduleName}
                getStudentsSelection={getStudentsSelection}
                onUpdate={onStatusUpdate}
              />
            )}
          </>
        )}
        {showShowMoreButton && (
          <button
            className="custom-btn btn btn-accent uppercase-text ml-16"
            onClick={toggleShowMoreTableColumns}
          >
            Show {showMoreTableColumns ? "Less" : "More"}
          </button>
        )}
        <div className="dropdown-container ml-16">
          <button className="custom-btn btn btn-accent uppercase-text flex flex-align-center">
            CSV Export
            <i className="material-icons ml-8">arrow_drop_down</i>
          </button>
          <div
            className="dropdown btn-dropdown csv-export-dropdown"
            style={{ width: "205px", zIndex: 2 }}
          >
            <ExportCSVButton
              className="small-text mb-0 link-text-secondary"
              fileName={`${(programScheduleName || "Trip").replace(
                / /g,
                "_"
              )}_Student_Registrations`}
              getExportData={() => getStudentsForExport(this.exportTypes.Basic)}
              getHeaders={() => this.getExportHeaders(this.exportTypes.Basic)}
              title="Basic Export"
            />
            <ExportCSVButton
              className="small-text mb-0 mt-16 link-text-secondary"
              fileName={`${(programScheduleName || "Trip").replace(
                / /g,
                "_"
              )}_Student_Registrations_Full`}
              getExportData={() => getStudentsForExport(this.exportTypes.Full)}
              getHeaders={() => this.getExportHeaders(this.exportTypes.Full)}
              title="Full Export"
            />
          </div>
        </div>
      </div>
    );
  }
}
