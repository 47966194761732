import React from "react";
import DateTimePicker from "react-datepicker";
import Select from "../../../shared/Select";
import Toggle from "../../../shared/Toggle";
import ValidatedInput from "../../../shared/ValidatedInput";
import { Editor as TinyMCE } from "@tinymce/tinymce-react";
import { TripTypes } from "../../TripEventConsts";
import { removeTimezoneFormatFromDate } from "../../../../lib";
import moment from "moment";

const { REACT_APP_TINYMCE_API_KEY } = process.env;

export default class EventSettings extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      event: { studentRegistrationEndDate, studentRegistrationStartDate },
    } = props;

    this.state = {
      initialStudentRegistrationEndDate: studentRegistrationEndDate,
      initialStudentRegistrationStartDate: studentRegistrationStartDate,
    };
  }

  render() {
    const {
      event: {
        hashtag,
        hasParentLetters,
        hasStartedShluchimEnrollment,
        id: eventId,
        isMarketingConsentRequired,
        isPassportIdRequired,
        isProofOfVaccinationRequired,
        isPublished,
        isTravelTrip,
        isTShirtSizeRequired,
        latestCancellationDate,
        shluchimEnrollmentStart,
        shluchimEnrollmentEnd,
        studentAcceptanceEmailContent,
        studentRegistrationStartDate,
        studentRegistrationEndDate,
        tripLocation,
        tripStartDate,
        tripEndDate,
        type,
      },
      onChange,
      onChangeEvt,
      onChangeType,
      submitAttempted,
      track: {
        ageMinimum,
        ageMaximum,
        maxStudentRegistrants,
        trackName,
        trackDescription,
      },
      trackFieldPrefix,
      systemLists: { tripTypes },
    } = this.props;

    const {
      initialStudentRegistrationEndDate,
      initialStudentRegistrationStartDate,
    } = this.state;

    return (
      <React.Fragment>
        <div className="program-settings-page-section">
          <p className="program-settings-page-title xxl-text fw-500">
            General Event Settings
          </p>
        </div>
        <div className="program-settings-page-section">
          <div className="flex flex-align-center mb-16 event-settings-inputs">
            <label className="input-label">Track Name</label>
            <ValidatedInput
              className="full-width"
              name={`${trackFieldPrefix}.trackName`}
              onChange={onChangeEvt}
              required={true}
              showRequired={submitAttempted}
              value={trackName}
            />
          </div>
          <p className="mb-8 flex flex-align-center">
            Track Description{" "}
            <span className="tooltip-container">
              <i className="material-icons large-text ml-8">info</i>
              <span className="tooltip">
                This will be displayed on the student registration form
              </span>
            </span>
          </p>
          <textarea
            className="custom-input mb-24"
            name={`${trackFieldPrefix}.trackDescription`}
            onChange={onChangeEvt}
            required={false}
            value={trackDescription}
          />
          <div className="event-settings-inputs">
            <div className="flex flex-align-center">
              <label className="input-label">Event type</label>
              <ValidatedInput
                className="full-width"
                input={
                  <Select
                    disabled={!!eventId && hasStartedShluchimEnrollment}
                    options={
                      tripTypes &&
                      tripTypes
                        .filter((type) => type.enumValue !== "Conference") //TODO COC-4582: Temporarily hiding Conference trip option
                        .map((type) => ({
                          display: type.displayValue,
                          value: type.enumValue,
                        }))
                    }
                  />
                }
                name="type"
                onChange={onChangeType}
                required={true}
                showRequired={submitAttempted}
                value={type}
              />
              {!!eventId && hasStartedShluchimEnrollment && (
                <span className="tooltip-container">
                  <i className="material-icons large-text ml-8">help</i>
                  <span className="tooltip">
                    Event type cannot be updated once event registration has
                    started
                  </span>
                </span>
              )}
            </div>
            <div className="flex flex-align-center">
              <label className="input-label">
                <div className="flex flex-align-center">
                  Event hashtag
                  <span className="tooltip-container">
                    <i className="material-icons large-text ml-8">info</i>
                    <span className="tooltip">
                      For student sharing upon successful registration. <br />
                      Enter a # symbol in front of hashtag text.
                    </span>
                  </span>
                </div>
                <span className="small-text accent-text mt-4">(optional)</span>
              </label>
              <input
                className="custom-input full-width"
                name="hashtag"
                onChange={onChangeEvt}
                value={hashtag}
              />
            </div>
            <div className="flex flex-align-center">
              <label className="input-label">
                Max student capacity{" "}
                <span className="small-text accent-text">(optional)</span>
              </label>
              <input
                className="custom-input"
                min="1"
                name={`${trackFieldPrefix}.maxStudentRegistrants`}
                onChange={onChangeEvt}
                value={maxStudentRegistrants}
                type="number"
              />
            </div>
            <div className="flex flex-align-center">
              <label className="input-label">
                Age restriction{" "}
                <span className="small-text accent-text">(optional)</span>
              </label>
              <div className="flex flex-align-center">
                <input
                  className="custom-input"
                  min="1"
                  name={`${trackFieldPrefix}.ageMinimum`}
                  onChange={onChangeEvt}
                  placeholder="Min"
                  value={ageMinimum}
                  type="number"
                />
                <span className="accent-text mr-8 ml-8">to</span>
                <input
                  className="custom-input"
                  min="1"
                  name={`${trackFieldPrefix}.ageMaximum`}
                  onChange={onChangeEvt}
                  placeholder="Max"
                  value={ageMaximum}
                  type="number"
                />
              </div>
            </div>
            <div className="flex flex-align-center">
              <label className="input-label">
                Require proof of vaccination
              </label>
              <div className="flex flex-align-center">
                <Toggle
                  name="isProofOfVaccinationRequired"
                  onChange={() => {
                    onChange(
                      "isProofOfVaccinationRequired",
                      !isProofOfVaccinationRequired
                    );
                  }}
                  options={[
                    {
                      value: true,
                      display: "Yes",
                    },
                    {
                      value: false,
                      display: "No",
                    },
                  ]}
                  value={isProofOfVaccinationRequired}
                />
              </div>
            </div>
            <div className="flex flex-align-center">
              <label className="input-label">Require T-shirt size</label>
              <div className="flex flex-align-center">
                <Toggle
                  name="isTShirtSizeRequired"
                  onChange={() => {
                    onChange("isTShirtSizeRequired", !isTShirtSizeRequired);
                  }}
                  options={[
                    {
                      value: true,
                      display: "Yes",
                    },
                    {
                      value: false,
                      display: "No",
                    },
                  ]}
                  value={isTShirtSizeRequired}
                />
              </div>
            </div>
            {isTravelTrip && (
              <>
                <div className="flex flex-align-center">
                  <label className="input-label">
                    Require legal marketing consent
                  </label>
                  <div className="flex flex-align-center">
                    <Toggle
                      name="isMarketingConsentRequired"
                      onChange={() => {
                        onChange(
                          "isMarketingConsentRequired",
                          !isMarketingConsentRequired
                        );
                      }}
                      options={[
                        {
                          value: true,
                          display: "Yes",
                        },
                        {
                          value: false,
                          display: "No",
                        },
                      ]}
                      value={isMarketingConsentRequired}
                    />
                  </div>
                </div>
                {type !== TripTypes.Conference && (
                  <div className="flex flex-align-center">
                    <label className="input-label flex flex-align-center">
                      Require passport ID
                      <span className="tooltip-container">
                        <i className="material-icons large-text ml-8">info</i>
                        <span className="tooltip">
                          If passport is not required, any form of goverment
                          issued ID will be requested
                        </span>
                      </span>
                    </label>
                    <div className="flex flex-align-center">
                      <Toggle
                        name="isPassportIdRequired"
                        onChange={() => {
                          onChange(
                            "isPassportIdRequired",
                            !isPassportIdRequired
                          );
                        }}
                        options={[
                          {
                            value: true,
                            display: "Yes",
                          },
                          {
                            value: false,
                            display: "No",
                          },
                        ]}
                        value={isPassportIdRequired}
                      />
                    </div>
                  </div>
                )}
                <div className="flex flex-align-center">
                  <label className="input-label">Has parent letters</label>
                  <div className="flex flex-align-center">
                    <Toggle
                      name="hasParentLetters"
                      onChange={() => {
                        onChange("hasParentLetters", !hasParentLetters);
                      }}
                      options={[
                        {
                          value: true,
                          display: "Yes",
                        },
                        {
                          value: false,
                          display: "No",
                        },
                      ]}
                      value={hasParentLetters}
                    />
                  </div>
                </div>
                <div className="flex flex-align-center">
                  <label className="input-label">Trip location</label>
                  <input
                    className="custom-input full-width"
                    name="tripLocation"
                    onChange={onChangeEvt}
                    value={tripLocation}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="program-settings-page-section">
          <p className="medium-text fw-700 mb-16">Dates</p>
          <div className="two-column-grid dates-two-column-grid">
            <div className="flex flex-align-center">
              <label className="input-label">Event start date</label>
              <DateTimePicker
                className="custom-input"
                dateFormat="MM/DD/YYYY h:mm a"
                readOnly
                selected={tripStartDate ? moment(tripStartDate) : null}
                showTimeSelect={true}
                timeIntervals={1}
              />
            </div>
            <div className="flex flex-align-center">
              <label className="input-label">Event end date</label>
              <DateTimePicker
                className="custom-input"
                dateFormat="MM/DD/YYYY h:mm a"
                readOnly
                selected={tripEndDate ? moment(tripEndDate) : null}
                showTimeSelect={true}
                timeIntervals={1}
              />
            </div>
            <div className="flex flex-align-center">
              <label className="input-label flex flex-align-center">
                Shluchim enrollment start
                <span className="tooltip-container">
                  <i className="material-icons large-text ml-8">info</i>
                  <span className="tooltip tooltip-medium-width">
                    This can only be set in the program details page - in the
                    Shluchim Registration Start Date field
                  </span>
                </span>
              </label>
              <DateTimePicker
                className="custom-input"
                dateFormat="MM/DD/YYYY h:mm a"
                readOnly
                selected={
                  shluchimEnrollmentStart
                    ? moment(shluchimEnrollmentStart)
                    : null
                }
                showTimeSelect={true}
                timeIntervals={1}
              />
              <span className="accent-text small-text ml-8">(EST)</span>
            </div>
            <div className="flex flex-align-center">
              <label className="input-label flex flex-align-center">
                Shluchim enrollment end
                <span className="tooltip-container">
                  <i className="material-icons large-text ml-8">info</i>
                  <span className="tooltip tooltip-medium-width">
                    This can only be set in the program details page - in the
                    Shluchim Registration End Date field
                  </span>
                </span>
              </label>
              <DateTimePicker
                className="custom-input"
                dateFormat="MM/DD/YYYY h:mm a"
                readOnly
                selected={
                  shluchimEnrollmentEnd ? moment(shluchimEnrollmentEnd) : null
                }
                showTimeSelect={true}
                timeIntervals={1}
              />
              <span className="accent-text small-text ml-8">(EST)</span>
            </div>
            <div className="flex flex-align-center">
              <label className="input-label">Student registration start</label>
              <DateTimePicker
                className={`custom-input ${
                  submitAttempted && !studentRegistrationStartDate
                    ? "error"
                    : ""
                }`} //= required={true}
                dateFormat="MM/DD/YYYY h:mm a"
                maxDate={
                  isPublished &&
                  initialStudentRegistrationStartDate &&
                  moment(initialStudentRegistrationStartDate).isSameOrBefore(
                    moment()
                  )
                    ? moment(initialStudentRegistrationStartDate)
                    : null
                }
                name="studentRegistrationStartDate"
                onChange={(dateTime) =>
                  onChange(
                    "studentRegistrationStartDate",
                    removeTimezoneFormatFromDate(dateTime)
                  )
                }
                selected={
                  studentRegistrationStartDate
                    ? moment(studentRegistrationStartDate)
                    : null
                }
                showTimeSelect={true}
                timeIntervals={1}
              />
              <span className="accent-text small-text ml-8">(EST)</span>
            </div>
            <div className="flex flex-align-center">
              <label className="input-label">Student registration end</label>
              <DateTimePicker
                className={`custom-input ${
                  submitAttempted && !studentRegistrationEndDate ? "error" : ""
                }`} //= required={true}
                dateFormat="MM/DD/YYYY h:mm a"
                minDate={
                  isPublished &&
                  initialStudentRegistrationEndDate &&
                  moment(initialStudentRegistrationEndDate).isSameOrBefore(
                    moment()
                  )
                    ? moment(initialStudentRegistrationEndDate)
                    : moment()
                }
                name="studentRegistrationEndDate"
                onChange={(dateTime) =>
                  onChange(
                    "studentRegistrationEndDate",
                    removeTimezoneFormatFromDate(dateTime)
                  )
                }
                selected={
                  studentRegistrationEndDate
                    ? moment(studentRegistrationEndDate)
                    : null
                }
                showTimeSelect={true}
                timeIntervals={1}
              />
              <span className="accent-text small-text ml-8">(EST)</span>
            </div>
            <div className="flex flex-align-center">
              <label className="input-label">Latest cancellation date</label>
              <DateTimePicker
                className={`custom-input ${
                  submitAttempted && !latestCancellationDate ? "error" : ""
                }`} //= required={true}
                dateFormat="MM/DD/YYYY h:mm a"
                name="latestCancellationDate"
                onChange={(dateTime) =>
                  onChange(
                    "latestCancellationDate",
                    removeTimezoneFormatFromDate(dateTime)
                  )
                }
                selected={
                  latestCancellationDate ? moment(latestCancellationDate) : null
                }
                showTimeSelect={true}
                timeIntervals={1}
              />
              <span className="accent-text small-text ml-8">(EST)</span>
            </div>
          </div>
        </div>
        {isTravelTrip && (
          <div className="program-settings-page-section">
            <p className="medium-text fw-700 mb-8">Acceptance Email Content</p>
            <p className="small-text accent-text mb-16">
              This text will be included in the students' acceptance emails,
              following the payment and cancellation details. Please include
              flight and all other travel info.
            </p>
            <TinyMCE
              apiKey={REACT_APP_TINYMCE_API_KEY}
              init={{
                auto_focus: false,
                height: 200,
                plugins:
                  "autolink code colorpicker fullscreen link lists paste preview table textcolor",
                menubar: "view edit insert format tools table",
                // See https://www.tiny.cloud/docs/advanced/editor-control-identifiers/
                toolbar:
                  "undo redo | " +
                  "styleselect | " +
                  "bold italic | " +
                  "fontsizeselect | " +
                  "alignleft aligncenter alignright alignjustify | " +
                  "bullist numlist outdent indent | " +
                  "forecolor backcolor",
                extended_valid_elements:
                  "script[language|type|src],iframe[src|style|width|height|scrolling|marginwidth|marginheight|frameborder]",
                invalid_elements: "",
              }}
              value={studentAcceptanceEmailContent}
              onEditorChange={(content) => {
                onChange("studentAcceptanceEmailContent", content);
              }}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}
