import React from "react";
import { browserHistory, Link } from "react-router";
import RaffleEventsCard from "./RaffleComponents/RaffleEventsCard";
import RaffleEnrollments from "./RaffleDetails/Enrollments/RaffleEnrollments";
import RaffleMetrics from "./RaffleDetails/Metrics/RaffleMetrics";
import RaffleOrders from "./RaffleDetails/Orders/RaffleOrders";
import Loader from "../common/Loader";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import { Unauthorized } from "../../lib/coc-common-components";

import RafflesApi from "../../services/resources/RafflesApi";
import SystemApi from "../../services/resources/SystemApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { PermissionClaims } from "../../services/AuthService";

export default class RaffleDetailsPage extends React.PureComponent {
  state = {
    authorized: true,

    currentCociEnrollment: {},
    currentCociEnrollmentErrorMessage: "",
    currentCociEnrollmentLoading: false,

    errorMessage: "",
    loading: true,
    raffleDetails: {},
    raffleList: [],

    systemLists: {
      countries: [],
      yearOverYearChartIntervals: [],
      raffleDonorAssociations: [],
    },
  };

  detailViews = {
    Orders: "orders",
    Shluchim: "shluchim",
    Metrics: "metrics",
  };

  apiSignal = axios.CancelToken.source();
  componentDidMount() {
    if (
      !AuthService.UserHasClaim(PermissionClaims.RaffleDataView) &&
      !AuthService.UserHasClaim(PermissionClaims.RaffleChabadHouseView)
    ) {
      this.setState({ authorized: false });
    }
    const {
      params: { scheduleId },
    } = this.props;
    this.loadRaffleList();
    this.getRaffleDetails(scheduleId);
    this.getSystemLists();
  }

  componentDidUpdate(prevProps) {
    //handle schedule param update
    if (this.props.params.scheduleId !== prevProps.params.scheduleId) {
      this.getRaffleDetails(this.props.params.scheduleId);
    }
    //if no view - default to orders view
    if (!this.props.location.query.view) {
      this.toggleView(this.detailViews.Orders);
    }
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  loadRaffleList = async () => {
    const raffleList = await RafflesApi.getRaffleEvents(this.apiSignal.token);
    if (raffleList.length) {
      this.setState({ raffleList });
    } else {
      this.setState({
        errorMessage: "404",
        loading: false,
      });
    }
  };
  getRaffleDetails = async (programScheduleId) => {
    try {
      this.setState({
        errorMessage: "",
        loading: true,
      });

      //update route imed (so that raffle schedule dropdown is updated)
      const {
        location: {
          query: { view },
        },
        params: { scheduleId: prevProgramScheduleId },
      } = this.props;
      if (
        programScheduleId &&
        programScheduleId.toString() !== prevProgramScheduleId
      ) {
        const queryUpdate = view ? `?view=${view}` : "";
        browserHistory.replace(
          `/grand-draw/${programScheduleId}${queryUpdate}`
        );
      }
      let raffleProgramScheduleId = programScheduleId;

      const raffleDetails = await RafflesApi.getRaffle(
        this.apiSignal.token,
        raffleProgramScheduleId
      );
      //coci-enrollment-only-admin needs to get current coci enrollment id for view enrollment link
      if (!AuthService.UserHasClaim(PermissionClaims.RaffleDataView)) {
        const raffleEventId = raffleDetails && raffleDetails.id;
        this.getCociEnrollmentId(raffleEventId);
      }
      this.setState({
        loading: false,
        raffleDetails,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        const is404 = (err.response && err.response.status) === 404;
        this.setState({
          errorMessage: is404
            ? "404"
            : ApiCallErrorMessageHandler(
                err,
                "Sorry, something went wrong and we could not retrieve Grand Draw details. Please try again."
              ),
          loading: false,
          raffleDetails: {},
        });
      }
    }
  };

  getCociEnrollmentId = async (raffleEventId) => {
    this.setState({ currentCociEnrollmentLoading: true });

    await RafflesApi.getCociEnrollmentId(raffleEventId, this.apiSignal.token)
      .then((data) => {
        this.setState({
          currentCociEnrollment: data,
          currentCociEnrollmentLoading: false,
        });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this.setState({
            currentCociEnrollment: {},
            currentCociEnrollmentErrorMessage: ApiCallErrorMessageHandler(err),
            currentCociEnrollmentLoading: false,
          });
        }
      });
  };

  getSystemLists = async () => {
    const systemLists = await SystemApi.lists([
      "countries",
      "yearOverYearChartIntervals",
      "raffleDonorAssociations",
    ]);
    this.setState({ systemLists });
  };

  toggleView = (detailView) => {
    const {
      location: {
        query: { view },
        pathname,
      },
    } = this.props;

    if (view !== detailView) {
      browserHistory.replace(`${pathname}?view=${detailView}`);
    }
  };

  render() {
    const {
      authorized,
      currentCociEnrollment,
      currentCociEnrollmentErrorMessage,
      currentCociEnrollmentLoading,
      errorMessage,
      loading,
      raffleDetails,
      raffleList,
      systemLists,
    } = this.state;
    const {
      location,
      location: {
        query: { view },
      },
      mobileMode,
    } = this.props;

    const {
      cadTicketPrice,
      cociCcProcessingFeePercentage,
      endDate,
      gbpTicketPrice,
      id: raffleId,
      isActive,
      isOpenForManualTicketEntry,
      isPastDrawingDate,
      isPublished,
      programScheduleID,
      raffleTitle,
      startDate,
      usdTicketPrice,
    } = raffleDetails;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const cociEnrollmentOnlyAdmin = !AuthService.UserHasClaim(
      PermissionClaims.RaffleDataView
    );
    const hasRaffleSettingsAccess = AuthService.UserHasClaim(
      PermissionClaims.RaffleFullEdit
    );
    const readOnlyAccess = !AuthService.UserHasClaim(
      PermissionClaims.RaffleDataEdit
    );

    return (
      <div className="raffles-page page container raffle-details-page">
        {!raffleDetails && loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage ? (
          <div className="empty-state">
            <img src="/img/error.svg" alt="error-robot" height="200" />
            <p className="error-text mt-32">
              Sorry, something went wrong and we could not retrieve Grand Draw
              details. Please try again.
            </p>
          </div>
        ) : (
          !!raffleDetails &&
          !loading && (
            <React.Fragment>
              <ProgramBreadcrumbsHeader
                className="mt-24"
                scheduleId={programScheduleID}
              />
              <div className="card trip-card flex flex-justify-space flex-align-center mb-40">
                <div className="flex flex-align-center">
                  <RaffleEventsCard
                    raffleDetails={raffleDetails}
                    rafflesList={raffleList}
                    onChangeRaffleEvent={(schedId) =>
                      this.getRaffleDetails(schedId)
                    }
                    scheduleId={programScheduleID}
                  />
                  {!isPublished && (
                    <span className="tag draft-tag ml-16">Draft</span>
                  )}
                </div>
                {cociEnrollmentOnlyAdmin ? (
                  <div className="flex flex-align-center">
                    {currentCociEnrollmentLoading ? (
                      <p className="accent-text">
                        Loading COCI Grand Draw Enrollment Details...
                      </p>
                    ) : currentCociEnrollmentErrorMessage ? (
                      <p className="error-text">
                        {currentCociEnrollmentErrorMessage ||
                          "Sorry, something went wrong and we could not retrieve COCI Grand Draw enrollment details. Please try again."}
                      </p>
                    ) : (
                      currentCociEnrollment && (
                        <Link
                          className="btn btn-primary btn-xs"
                          to={`/grand-draw/${programScheduleID}/enrollment/${currentCociEnrollment.id}`}
                        >
                          View COCI Enrollment
                        </Link>
                      )
                    )}
                  </div>
                ) : (
                  <div className="flex flex-align-center">
                    <Link className="btn btn-accent btn-xs" to="/grand-draw">
                      Grand Draw History
                      {(isActive || isOpenForManualTicketEntry) && " & Metrics"}
                    </Link>
                    {hasRaffleSettingsAccess &&
                      (isPublished ? (
                        <Link
                          className="btn btn-accent-secondary btn-xs ml-8"
                          to={`/grand-draw/settings/${programScheduleID}`}
                        >
                          View Settings
                        </Link>
                      ) : (
                        <Link
                          className="btn btn-accent-secondary btn-xs ml-8"
                          to={`/grand-draw/new/${programScheduleID}`}
                        >
                          Edit Draft
                        </Link>
                      ))}
                  </div>
                )}
              </div>
              {!cociEnrollmentOnlyAdmin && isPublished && (
                <React.Fragment>
                  <div className="raffle-details-tabs flex">
                    <p
                      className={`large-text ${
                        view === this.detailViews.Orders ? "active" : ""
                      }`}
                      onClick={() => this.toggleView(this.detailViews.Orders)}
                    >
                      Orders
                    </p>
                    <p
                      className={`large-text ml-40 ${
                        view === this.detailViews.Shluchim ? "active" : ""
                      }`}
                      onClick={() => this.toggleView(this.detailViews.Shluchim)}
                    >
                      Shluchim
                    </p>
                    <p
                      className={`large-text ml-40 ${
                        view === this.detailViews.Metrics ? "active" : ""
                      }`}
                      onClick={() => this.toggleView(this.detailViews.Metrics)}
                    >
                      Metrics
                    </p>
                  </div>
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="raffle-details-cards">
                      {view === this.detailViews.Orders && (
                        <RaffleOrders
                          cociCcProcessingFeePercentage={
                            cociCcProcessingFeePercentage
                          }
                          location={location}
                          mobileMode={mobileMode}
                          raffleId={raffleId}
                          raffleIsOpenForManualTicketEntry={
                            isOpenForManualTicketEntry
                          }
                          raffleIsPastDrawingDate={isPastDrawingDate}
                          raffleTitle={raffleTitle}
                          readOnlyAccess={readOnlyAccess}
                          systemCountries={systemLists.countries}
                          systemRaffleDonorAssociations={
                            systemLists.raffleDonorAssociations
                          }
                          usdTicketPrice={usdTicketPrice}
                          cadTicketPrice={cadTicketPrice}
                          gbpTicketPrice={gbpTicketPrice}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      )}

                      {view === this.detailViews.Shluchim && (
                        <RaffleEnrollments
                          mobileMode={mobileMode}
                          raffleEndDate={endDate}
                          raffleId={raffleId}
                          raffleProgramScheduleId={programScheduleID}
                          raffleTitle={raffleTitle}
                        />
                      )}

                      {view === this.detailViews.Metrics && (
                        <RaffleMetrics
                          raffleDetails={raffleDetails}
                          raffleId={raffleId}
                          systemRaffleDaysToEnd={
                            systemLists.yearOverYearChartIntervals
                          }
                          toOrdersTab={() =>
                            this.toggleView(this.detailViews.Orders)
                          }
                        />
                      )}
                    </div>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )
        )}
      </div>
    );
  }
}
