import React from "react";
import Dropzone from "react-dropzone";
import SelectSearch from "react-select";
// import Radio from "../../shared/Radio";
import ContentApi from "../../../services/resources/ContentApi";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";

const prizeImagesByCurrency = [
  { name: "usdPrizeImageURL", title: "USD Prize Image" },
  { name: "cadPrizeImageURL", title: "CAD Prize Image" },
  { name: "gbpPrizeImageURL", title: "GBP Prize Image" },
];

export default class PrizeDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitAttempted: false,
      syncPrizeTitles: false,
      newLocation: "",
      localWinnersLocations: [],
      hasLocalWinners: false,
      initialState: {},
    };
  }

  componentDidMount() {
    const { prize } = this.props;
    if (prize) {
      this.initializePrizeState();
    }
  }

  componentDidUpdate(prevProps) {
    const { prizeIndex } = this.props;
    if (prevProps.prizeIndex !== prizeIndex) {
      this.initializePrizeState();
    }
  }

  initializePrizeState = () => {
    const { prize, locations } = this.props;

    const syncPrizeTitles =
      prize.usdPrizeTitle &&
      prize.usdPrizeTitle === prize.cadPrizeTitle &&
      prize.cadPrizeTitle === prize.gbpPrizeTitle;

    const hasLocalWinners = prize.chabadHousePrizes.length > 0 ? true : false;

    let localWinnersLocations = [];
    if (prize.chabadHousePrizes) {
      prize.chabadHousePrizes.forEach((prize, i) => {
        const index = locations.findIndex(
          (location) => location.chabadHouseID === prize.chabadHouseID
        );
        if (index !== -1) {
          localWinnersLocations.push(locations[index]);
        }
      });
      localWinnersLocations = localWinnersLocations.map((location) => ({
        label: location.campusName,
        value: location,
      }));
    }

    const prizeState = {
      hasLocalWinners,
      newLocation: "",
      localWinnersLocations,
      syncPrizeTitles,
      submitAttempted: false,
    };
    prizeState.initialState = _cloneDeep(prizeState);

    this.setState(prizeState);
  };

  onChange = (name, value) => {
    this.props.onChangePrizeProperties({
      [name]: value,
    });
  };

  onChangeEvent = (event) => {
    this.onChange(event.target.name, event.target.value);
  };

  onChangePrizeTitle = (event) => {
    const prizeTitle = event.target.value;

    if (this.state.syncPrizeTitles) {
      this.props.onChangePrizeProperties({
        usdPrizeTitle: prizeTitle,
        cadPrizeTitle: prizeTitle,
        gbpPrizeTitle: prizeTitle,
      });
    } else {
      this.onChangeEvent(event);
    }
  };

  onCancelChanges = () => {
    this.setState(this.state.initialState);
    this.props.onCancelChanges();
  };

  onSaveChanges = () => {
    this.setState({ submitAttempted: true }, () => {
      const { prize, usedPrizePlaces } = this.props;
      const { hasLocalWinners, newLocation } = this.state;
      if (
        !prize.usdPrizeTitle ||
        !prize.cadPrizeTitle ||
        !prize.gbpPrizeTitle ||
        (prize.place && usedPrizePlaces.indexOf(prize.place.toString()) >= 0) ||
        newLocation ||
        (hasLocalWinners &&
          (!prize.chabadHousePrizes || !prize.chabadHousePrizes.length))
      ) {
        return;
      }

      this.props.onSaveChanges();

      this.setState({ submitAttempted: false });
    });
  };

  toggleSyncPrizeTitles = (event) => {
    const syncPrizeTitles = event.target.checked;
    this.setState({ syncPrizeTitles });

    if (syncPrizeTitles) {
      const { prize } = this.props;
      const prizeTitle =
        prize.usdPrizeTitle || prize.cadPrizeTitle || prize.gbpPrizeTitle;

      this.props.onChangePrizeProperties({
        usdPrizeTitle: prizeTitle,
        cadPrizeTitle: prizeTitle,
        gbpPrizeTitle: prizeTitle,
      });
    }
  };

  uploadPrizeImage = async (name, acceptedFiles) => {
    if (acceptedFiles.length) {
      const fileURL = await ContentApi.uploadFile(
        acceptedFiles[0],
        "raffle_prize"
      );

      this.onChange(name, fileURL);
    }
  };

  addLocalWinnersLocation = () => {
    const { localWinnersLocations, newLocation } = this.state;
    const updatedLocalWinnersLocations = [...localWinnersLocations];
    updatedLocalWinnersLocations.push(newLocation);
    this.setState({
      newLocation: "",
      localWinnersLocations: updatedLocalWinnersLocations,
    });
    this.onChange(
      "chabadHousePrizes",
      updatedLocalWinnersLocations.map((location) => ({
        chabadHouseID: location.value && location.value.chabadHouseID,
      }))
    );
  };

  deleteLocalWinnersLocation = (location) => {
    const { localWinnersLocations } = this.state;
    let updatedLocalWinnersLocations = [...localWinnersLocations];
    updatedLocalWinnersLocations = updatedLocalWinnersLocations.filter(
      (l) => l.value !== location
    );
    this.setState({
      localWinnersLocations: updatedLocalWinnersLocations,
    });
    if (updatedLocalWinnersLocations.length < 1) {
      this.setState({ hasLocalWinners: false });
    }
    this.onChange(
      "chabadHousePrizes",
      updatedLocalWinnersLocations.map((location) => ({
        chabadHouseID: location.value && location.value.chabadHouseID,
      }))
    );
  };

  render() {
    const {
      close,
      getErrorClassName,
      editMode,
      onEditPrize,
      initialPrize,
      isNewPrize,
      prize,
      prize: {
        cadPrizeTitle,
        gbpPrizeTitle,
        numberOfWinners,
        place,
        // prizeOfferedIn,
        chabadHousePrizes,
        usdPrizeTitle,
      },
      readOnly,
      submitAttempted,
      usedPrizePlaces,
    } = this.props;

    const {
      hasLocalWinners,
      newLocation,
      submitAttempted: prizeSubmitAttempted,
      syncPrizeTitles,
    } = this.state;

    return (
      <React.Fragment>
        <div className="flex flex-justify-space mb-24">
          <p className="xl-text">
            Prize Details
            {!readOnly && !isNewPrize && !editMode && (
              <i
                className="material-icons large-text link-text ml-8"
                onClick={onEditPrize}
              >
                edit
              </i>
            )}
          </p>
          {!isNewPrize && !editMode && (
            <i
              className="material-icons large-text link-text-secondary"
              onClick={close}
            >
              close
            </i>
          )}
        </div>
        <div className="prize-details-form">
          <div className="mb-16">
            <p className="medium-text fw-700 mb-16">Prize Title</p>
            <div className="flex custom-checkbox-container prize-title-checkbox">
              <input
                className="custom-checkbox"
                checked={syncPrizeTitles}
                disabled={!editMode}
                id="sync-titles-checkbox"
                onChange={this.toggleSyncPrizeTitles}
                type="checkbox"
                value={syncPrizeTitles}
              />
              <label
                className="flex-align-center"
                htmlFor="sync-titles-checkbox"
              >
                <span>All prize titles are the same</span>
              </label>
            </div>
          </div>
          <div className="flex flex-align-center mb-16 relative">
            <label className="raffle-label">USD Prize Title</label>
            <input
              type="text"
              className={getErrorClassName(
                "custom-input",
                (submitAttempted || prizeSubmitAttempted) && !usdPrizeTitle
              )}
              disabled={!editMode}
              name="usdPrizeTitle"
              onChange={this.onChangePrizeTitle}
              tabIndex={50}
              value={usdPrizeTitle}
            />
            {prizeSubmitAttempted && !usdPrizeTitle && (
              <span className="error-message">USD Prize title is required</span>
            )}
          </div>
          <div className="flex flex-align-center mb-16 relative">
            <label className="raffle-label">CAD Prize Title</label>
            <input
              type="text"
              className={getErrorClassName(
                "custom-input",
                (submitAttempted || prizeSubmitAttempted) && !cadPrizeTitle
              )}
              disabled={!editMode}
              name="cadPrizeTitle"
              onChange={this.onChangePrizeTitle}
              tabIndex={50}
              value={cadPrizeTitle}
            />
            {prizeSubmitAttempted && !cadPrizeTitle && (
              <span className="error-message">CAD Prize title is required</span>
            )}
          </div>
          <div className="flex flex-align-center mb-16 relative">
            <label className="raffle-label">GBP Prize Title</label>
            <input
              type="text"
              className={getErrorClassName(
                "custom-input",
                (submitAttempted || prizeSubmitAttempted) && !gbpPrizeTitle
              )}
              disabled={!editMode}
              name="gbpPrizeTitle"
              onChange={this.onChangePrizeTitle}
              tabIndex={50}
              value={gbpPrizeTitle}
            />
            {prizeSubmitAttempted && !gbpPrizeTitle && (
              <span className="error-message">GBP Prize title is required</span>
            )}
          </div>
          <div className="mt-24 mb-16">
            <p className="medium-text fw-700 mb-16">Prize Info</p>
            <div className="flex flex-align-center">
              <div className="flex flex-align-center">
                <label className="raffle-label">Prize Place</label>
                <input
                  type="number"
                  className={getErrorClassName(
                    "custom-input",
                    (submitAttempted && !place) ||
                      (prizeSubmitAttempted &&
                        place &&
                        usedPrizePlaces.indexOf(place.toString()) >= 0)
                  )}
                  disabled={!editMode}
                  name="place"
                  min="1"
                  onChange={this.onChangeEvent}
                  tabIndex={100}
                  value={place}
                />
                {prizeSubmitAttempted &&
                  place &&
                  usedPrizePlaces.indexOf(place.toString()) >= 0 && (
                    <span className="error-text">
                      A prize has already been assigned to this place.
                    </span>
                  )}
              </div>
              <div className="flex flex-align-center ml-32">
                <label className="raffle-label">Winners</label>
                <input
                  type="number"
                  className={getErrorClassName(
                    "custom-input",
                    submitAttempted && !numberOfWinners
                  )}
                  disabled={!editMode}
                  name="numberOfWinners"
                  min="1"
                  onChange={this.onChangeEvent}
                  tabIndex={110}
                  value={numberOfWinners}
                />
              </div>
            </div>
          </div>
          <div className="flex mt-32">
            {prizeImagesByCurrency.map((prize) => (
              <div style={{ flex: "1" }}>
                <p className="medium-text fw-700 mb-16">
                  {prize.title}
                  <span className="tooltip-container">
                    <i className="material-icons medium-text ml-4">info</i>
                    <span className="tooltip">
                      Upload a PNG image to display on the public site.
                      <br />
                      Recommended file size for images is around 200 KB.
                    </span>
                  </span>
                </p>
                <div className="flex flex-align-center image-upload mb-24">
                  <Dropzone
                    accept={"image/png"}
                    disabled={!editMode}
                    onDrop={(files) => this.uploadPrizeImage(prize.name, files)}
                    tabIndex={130}
                    title="Drag an image here or click to find one on your computer."
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className={`dropzone ${
                          !this.props.prize[prize.name] ? "no-image" : ""
                        } ${!editMode ? "disabled" : ""}`}
                      >
                        <input {...getInputProps()} />
                        <img
                          src={
                            this.props.prize[prize.name]
                              ? this.props.prize[prize.name]
                              : "/img/placeholder.svg"
                          }
                          alt="prize"
                        />
                        <p className="link-text uppercase-text fw-700 ml-16">
                          {this.props.prize[prize.name] ? "Change " : "Upload "}{" "}
                          Image
                        </p>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            ))}

            {/* Removing PrizeOfferedIn implementation as all prizes will be available in Texas for now */}
            {/* <div style={{ flex: "1" }} className="ml-32">
              <p className="medium-text fw-700 mb-16">Prize will be offered</p>
              <div>
                <Radio
                  disabled={!editMode}
                  name="prizeOfferedIn"
                  onChange={this.onChange}
                  options={[
                    {
                      value: "Everywhere",
                      display: "Everywhere including Texas"
                    },
                    {
                      value: "EverywhereExcludingTexas",
                      display: "Everywhere excluding Texas",
                      displayLabel: (
                        <span>
                          Everywhere <span className="fw-700">excluding</span>{" "}
                          Texas
                        </span>
                      )
                    },
                    {
                      value: "OnlyTexas",
                      display: "Only in Texas"
                    }
                  ]}
                  tabIndex={140}
                  value={prizeOfferedIn}
                />
              </div>
            </div> */}
          </div>
          <div>
            <div className="mb-16">
              <p className="medium-text fw-700">Local Winners</p>
              <div className="flex custom-checkbox-container prize-title-checkbox">
                <input
                  className="custom-checkbox"
                  checked={hasLocalWinners}
                  disabled={!editMode}
                  id="has-local-winners-checkbox"
                  onChange={() =>
                    this.setState({
                      hasLocalWinners: !this.state.hasLocalWinners,
                    })
                  }
                  type="checkbox"
                  value={hasLocalWinners}
                />
                <label
                  className="flex-align-center"
                  htmlFor="has-local-winners-checkbox"
                >
                  <span>Prize has additional local winners</span>
                </label>
              </div>
            </div>
            {hasLocalWinners && (
              <>
                {this.state.localWinnersLocations.map((location) => {
                  location = location.value;
                  return (
                    <div
                      className="flex flex-align-center mb-8"
                      key={location.chabadHouseID}
                    >
                      <div className="local-winners-row">
                        <p className="small-text">{location.chabadHouseName}</p>
                        <p className="small-text">{location.campusName}</p>
                        <p className="small-text">{location.shluchim}</p>
                      </div>
                      {editMode && (
                        <i
                          className="material-icons accent-text xl-text ml-8 pointer"
                          onClick={() =>
                            this.deleteLocalWinnersLocation(location)
                          }
                        >
                          delete
                        </i>
                      )}
                    </div>
                  );
                })}
                <div className="flex flex-align-center">
                  <SelectSearch
                    className="add-local-winners-input"
                    disabled={!editMode}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(option) =>
                      this.setState({ newLocation: option })
                    }
                    options={this.props.locations
                      .filter((l) => {
                        const chabadHouseIDs =
                          this.state.localWinnersLocations.map((location) => {
                            return location.value.chabadHouseID;
                          });
                        return !chabadHouseIDs.includes(l.chabadHouseID);
                      })
                      .map((location) => ({
                        value: location,
                        label: [
                          location.chabadHouseName,
                          location.campusName,
                          location.shluchim,
                        ]
                          .filter((n) => n)
                          .join(" - "),
                      }))}
                    placeholder={"Add Location"}
                    style={{
                      height: 24,
                      border: "1px solid #edecec",
                      fontSize: 13,
                    }}
                    value={newLocation}
                  />
                  <button
                    className={`uppercase-text ml-16 custom-btn ${
                      !editMode || !newLocation
                        ? "accent-text"
                        : "link-text accent-text-secondary"
                    }`}
                    disabled={!editMode || !newLocation}
                    onClick={this.addLocalWinnersLocation}
                    style={{ background: "transparent", border: "none" }}
                  >
                    Add
                  </button>
                </div>
                {prizeSubmitAttempted && (
                  <p className="error-text mt-4">
                    {newLocation
                      ? "Click ADD to add the selected location before saving, or clear your location selection"
                      : !chabadHousePrizes || !chabadHousePrizes.length
                      ? "Enter local winners"
                      : ""}
                  </p>
                )}
              </>
            )}
            {editMode && (
              <div className="mt-40 prize-details-form-btns">
                <button
                  className="btn btn-cancel btn-medium uppercase-text"
                  onClick={this.onCancelChanges}
                  tabIndex={160}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-accent btn-medium ml-24 uppercase-text"
                  disabled={_isEqual(initialPrize, prize)}
                  onClick={this.onSaveChanges}
                  tabIndex={150}
                >
                  {isNewPrize ? "Add" : "Update"} Prize
                </button>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
