import React from "react";
import { formatNumber, pluralizeText } from "../../lib";
import { EduProgramTypes } from "./EduConsts";

export default ({
  eduProgram: {
    activeChabadHousesCount,
    activeCoursesCount,
    activeSchedulesCount,
    activeStudentsCount,
    coursesCount,
    chabadHousesCount,
    studentsCount,
    type,
  },
}) => (
  <div
    className="flex courses-header-cards"
    style={{ maxWidth: "calc(100% - 352px)" }}
  >
    {type === EduProgramTypes.YourIsrael ? (
      <React.Fragment>
        <div className="card text-center mr-16">
          <p className="fw-900 xxl-text">
            {formatNumber(activeChabadHousesCount)}
          </p>
          Active {pluralizeText("Location", activeChabadHousesCount)}
        </div>

        <div className="card text-center mr-16">
          <p className="fw-900 xxl-text">{formatNumber(activeStudentsCount)}</p>
          Active {pluralizeText("Student", activeStudentsCount)}
        </div>
        <div className="card text-center mr-16">
          <p className="fw-900 xxl-text">
            {formatNumber(activeSchedulesCount)}
          </p>
          Active {pluralizeText("Schedule", activeSchedulesCount)}
        </div>
      </React.Fragment>
    ) : type === EduProgramTypes.SinaiScholars ? (
      <React.Fragment>
        <div className="card text-center mr-16">
          <p className="fw-900 xxl-text">{formatNumber(chabadHousesCount)}</p>
          {pluralizeText("Location", chabadHousesCount)}
        </div>
        <div className="card text-center mr-16">
          <p className="fw-900 xxl-text">{formatNumber(studentsCount)}</p>
          {pluralizeText("Student", studentsCount)}
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="card text-center mr-16">
          <p className="fw-900 xxl-text">
            {formatNumber(activeChabadHousesCount)}
          </p>
          Active {pluralizeText("Location", activeChabadHousesCount)}
        </div>
        <div className="card text-center mr-16">
          <p className="fw-900 xxl-text">{formatNumber(studentsCount)}</p>
          {pluralizeText("Student", studentsCount)}
        </div>
        <div className="card text-center mr-16">
          <p className="fw-900 xxl-text">{formatNumber(coursesCount)}</p>
          Total Courses
        </div>
        <div className="card text-center">
          <p className="fw-900 xxl-text">{formatNumber(activeCoursesCount)}</p>
          Active {pluralizeText("Course", activeCoursesCount)}
        </div>
      </React.Fragment>
    )}
  </div>
);
