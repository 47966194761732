import React from "react";
import { EduProgramTypes } from "../EduConsts";

export default class LocationsTableHeader extends React.PureComponent {
  render() {
    const { eduProgramType } = this.props;

    const columns = [
      "Shliach",
      "Shlucha",
      "Chabad House",
      "Campus",
      "Last Schedule Created",
      ...(eduProgramType === EduProgramTypes.JewishU
        ? ["Membership Status"]
        : []),
      "",
    ];

    return (
      <div className="courses-locations-table-header">
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
