import React from "react";
import { browserHistory } from "react-router";
import PaginatedTable from "../../../shared/PaginatedTable";
import RaffleOrderActions from "./RaffleOrderActions";
import RaffleOrderFilters from "./RaffleOrderFilters";
import RaffleOrderRecord from "./RaffleOrderRecord";
import RaffleOrderDetailsModal from "./EditRaffleOrder/RaffleOrderDetailsModal";
import RaffleOrderEmailModal from "./RaffleOrderEmailModal";
import RaffleOrderRefundModal from "./RaffleOrderRefundModal";

import RafflesApi from "../../../../services/resources/RafflesApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";
import debounce from "lodash.debounce";
import queryString from "query-string";

export default class RaffleOrders extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentOrder: {},
      errorMessage: "",
      filters: {
        association: "",
        keyword: props.location.query.order || "",
        paymentTypes: [],
        paymentType: "",
        referrerID: "",
      },
      loading: false,
      orders: [],
      page: 1,
      results: 12,
      showOrderDetailsModal: false,
      showOrderEmailModal: false,
      showOrderRefundModal: false,
      sortBy: "dateDesc",
      success: true,
      totalOrders: 0,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getOrders = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        RafflesApi.getOrders(
          this.apiSignal.token,
          this.props.raffleId,
          page,
          results,
          sortBy,
          this.state.filters,
          this.props.enrollmentId
        )
          .then(({ orders, totalOrders }) => {
            this.setState({
              loading: false,
              orders,
              totalOrders,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                orders: [],
                success: false,
                totalOrders: 0,
              });
            }
          });
      }
    );
  };

  getOrdersForExport = async (exportAsTickets) => {
    const { enrollmentId, raffleId } = this.props;
    const { sortBy, filters } = this.state;

    const ordersForExport = await RafflesApi.getOrders(
      this.apiSignal.token,
      raffleId,
      undefined,
      undefined,
      sortBy,
      filters,
      enrollmentId,
      true,
      exportAsTickets
    );

    return ordersForExport.orders;
  };

  getOrderDetails = async () => {
    const { currentOrder } = this.state;
    if (!currentOrder) {
      return;
    }

    const orderDetailsResponse = {};

    try {
      orderDetailsResponse.orderDetails = await RafflesApi.getOrderDetails(
        this.apiSignal.token,
        this.state.currentOrder.purchaseID
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        orderDetailsResponse.error = err;
        orderDetailsResponse.errorMessage = ApiCallErrorMessageHandler(err);
      }
    }

    return orderDetailsResponse;
  };

  toggleOrderModal = (showModalProperty, show, order) => {
    this.setState({
      currentOrder: show ? order : {},
      [showModalProperty]: show,
    });
  };

  updateOrderDetails = async (orderUpdate) => {
    const updateOrderResponse = {};
    try {
      await RafflesApi.updateOrderDetails(this.apiSignal.token, orderUpdate);
      updateOrderResponse.success = true;

      //reload orders table
      this.getOrders(this.state.page);
    } catch (err) {
      updateOrderResponse.error = err;
      updateOrderResponse.errorMessage = ApiCallErrorMessageHandler(err);
    }

    return updateOrderResponse;
  };

  onFilterOrders = (name, value, debounce) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      debounce ? this.getOrdersDebounce : this.getOrders
    );
    const {
      location: { pathname, query },
    } = this.props;
    if (name === "keyword" && query.order) {
      const { order, ...queryRest } = query;
      browserHistory.replace(`${pathname}?${queryString.stringify(queryRest)}`);
    }
  };

  getOrdersDebounce = debounce(this.getOrders, 500);

  render() {
    const {
      cociCcProcessingFeePercentage,
      enrollment,
      getEnrollmentMetricsAndSales,
      location,
      mobileMode,
      raffleId,
      raffleIsActive,
      raffleIsOpenForManualTicketEntry,
      raffleIsPastDrawingDate,
      raffleTitle,
      readOnlyAccess,
      systemCountries,
      systemRaffleDonorAssociations,
      usdTicketPrice,
      cadTicketPrice,
      gbpTicketPrice,
      startDate,
      endDate,
    } = this.props;

    const {
      currentOrder,
      filters,
      loading,
      orders,
      page,
      results,
      showOrderDetailsModal,
      showOrderEmailModal,
      showOrderRefundModal,
      sortBy,
      success,
      totalOrders,
    } = this.state;

    return (
      <div className="card raffle-details-card">
        <div className="flex flex-justify-space flex-align-center mb-24">
          <p className="xxl-text fw-500">All Orders</p>

          <RaffleOrderActions
            cociCcProcessingFeePercentage={cociCcProcessingFeePercentage}
            enrollment={enrollment}
            getOrdersForExport={this.getOrdersForExport}
            raffleIsActive={raffleIsActive}
            raffleIsOpenForManualTicketEntry={raffleIsOpenForManualTicketEntry}
            raffleTitle={raffleTitle}
            readOnlyAccess={readOnlyAccess}
            reloadOrders={() => {
              this.getOrders(1, results, sortBy);
              if (enrollment) {
                getEnrollmentMetricsAndSales();
              }
            }}
            systemCountries={systemCountries}
            systemRaffleDonorAssociations={systemRaffleDonorAssociations}
          />
        </div>

        <PaginatedTable
          filterComponent={
            <RaffleOrderFilters
              enrollment={enrollment}
              filters={filters}
              onChange={this.onFilterOrders}
              systemRaffleDonorAssociations={systemRaffleDonorAssociations}
            />
          }
          loading={loading}
          loadData={this.getOrders}
          mobileMode={mobileMode}
          page={page}
          records={orders}
          renderHeader={() => (
            <div
              className={`raffle-details-table-header raffle-details-order-${
                enrollment ? "by-enrollment-" : ""
              }table-row`}
            >
              <p>Donor Info</p>
              <p>Order #</p>
              <p>Amount</p>
              <p>Date</p>
              <p>{enrollment ? "Team" : "Chabad House / Shliach"}</p>
              <p>Method</p>
              {!readOnlyAccess && !enrollment && (
                <p className="text-center">Refund</p>
              )}
              {!readOnlyAccess && <p className="text-center">Resend Email</p>}
            </div>
          )}
          renderRow={(order) => (
            <RaffleOrderRecord
              cociCcProcessingFeePercentage={cociCcProcessingFeePercentage}
              enrollment={enrollment}
              key={order.orderID}
              order={order}
              readOnlyAccess={readOnlyAccess}
              refundOrder={() =>
                this.toggleOrderModal("showOrderRefundModal", true, order)
              }
              resendOrderEmail={() =>
                this.toggleOrderModal("showOrderEmailModal", true, order)
              }
              viewOrderDetails={() =>
                this.toggleOrderModal("showOrderDetailsModal", true, order)
              }
              usdTicketPrice={usdTicketPrice}
              cadTicketPrice={cadTicketPrice}
              gbpTicketPrice={gbpTicketPrice}
            />
          )}
          results={results}
          showResultsView={true}
          sortBy={sortBy}
          sortOptions={
            enrollment
              ? [
                  { name: "Date: New-Old", id: "DateDesc" },
                  { name: "Date: Old-New", id: "DateAsc" },
                  { name: "Amount: Low-High", id: "AmountAsc" },
                  { name: "Amount: High-Low", id: "AmountDesc" },
                ]
              : [
                  { name: "Date: New-Old", id: "DateDesc" },
                  { name: "Date: Old-New", id: "DateAsc" },
                  { name: "Chabad House", id: "ChabadHouse" },
                  { name: "Amount: Low-High", id: "AmountAsc" },
                  { name: "Amount: High-Low", id: "AmountDesc" },
                ]
          }
          success={success}
          totalCount={totalOrders}
        />

        {showOrderRefundModal && (
          <RaffleOrderRefundModal
            close={() => this.toggleOrderModal("showOrderRefundModal", false)}
            cociCcProcessingFeePercentage={cociCcProcessingFeePercentage}
            order={currentOrder}
            raffleIsPastDrawingDate={raffleIsPastDrawingDate}
            reloadOrders={() => this.getOrders(page, results, sortBy)}
            show={showOrderRefundModal}
          />
        )}

        {showOrderDetailsModal && (
          <RaffleOrderDetailsModal
            close={() => this.toggleOrderModal("showOrderDetailsModal", false)}
            cociCcProcessingFeePercentage={cociCcProcessingFeePercentage}
            getOrderDetails={this.getOrderDetails}
            location={location}
            order={currentOrder}
            raffleId={raffleId}
            readOnlyAccess={readOnlyAccess}
            show={showOrderDetailsModal}
            systemCountries={systemCountries}
            systemRaffleDonorAssociations={systemRaffleDonorAssociations}
            updateOrderDetails={this.updateOrderDetails}
            enrollmentTicketPrice={enrollment && enrollment.ticketPrice}
            usdTicketPrice={usdTicketPrice}
            cadTicketPrice={cadTicketPrice}
            gbpTicketPrice={gbpTicketPrice}
            startDate={startDate}
            endDate={endDate}
          />
        )}

        {showOrderEmailModal && (
          <RaffleOrderEmailModal
            close={() => this.toggleOrderModal("showOrderEmailModal", false)}
            order={currentOrder}
            show={showOrderEmailModal}
          />
        )}
      </div>
    );
  }
}
