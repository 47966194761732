import React from "react";
import Loader from "../../../common/Loader";
import Modal from "react-modal";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import Radio from "../../../shared/Radio";

import RafflesApi from "../../../../services/resources/RafflesApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";
import { formatCurrency, pluralizeText } from "../../../../lib";
import { notify } from "react-notify-toast";
import _cloneDeep from "lodash.clonedeep";
import Big from "big.js";

export default class RaffleOrderRefundModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      fullRefund: true,
      loading: false,
      refundQuantity: "",
      showRefundConfirmationModal: false,
      submitAttempted: false,
    };

    this.state.initialState = _cloneDeep(this.state);
  }

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onChange = (name, value) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "fullRefund") {
          this.setState({ refundQuantity: this.state.fullRefund ? "" : "1" });
        }
      }
    );
  };

  onClose = () => {
    this.setState(this.state.initialState, this.props.close);
  };

  onRefundOrder = () => {
    this.setState({ submitAttempted: true });

    const { fullRefund, refundQuantity } = this.state;
    if (
      !fullRefund &&
      (!refundQuantity || refundQuantity > this.props.order.quantity)
    ) {
      return;
    }

    this.setState({ showRefundConfirmationModal: true });
  };

  refundOrder = () => {
    const {
      order: { orderID, orderNumber, quantity },
      reloadOrders,
    } = this.props;

    this.setState(
      {
        errorMessage: "",
        loading: true,
      },
      () => {
        RafflesApi.refundOrder(
          this.apiSignal.token,
          orderID,
          this.state.refundQuantity || quantity
        )
          .then(() => {
            notify.show(`Order #${orderNumber} has been refunded`, "success");
            reloadOrders();
            this.onClose();
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
              });
            }
          });
      }
    );
  };

  render() {
    const {
      cociCcProcessingFeePercentage,
      order: {
        currencyCode,
        didDonorPayProcessingFee,
        donorFirstName,
        donorLastName,
        orderNumber,
        processingFeeDonorAmount,
        singleTicketPrice,
        quantity,
        ticketPriceAmount,
      },
      raffleIsPastDrawingDate,
      show,
    } = this.props;
    const {
      errorMessage,
      fullRefund,
      loading,
      refundQuantity,
      showRefundConfirmationModal,
      submitAttempted,
    } = this.state;

    const cociCcProcessingFeeAsDecimal = Big(cociCcProcessingFeePercentage).div(
      100
    );

    const refundTicketPriceAmount = fullRefund
      ? Big(ticketPriceAmount)
      : Big(refundQuantity || 0).times(singleTicketPrice);
    const refundProcessingFeeDonorAmount = fullRefund
      ? Big(processingFeeDonorAmount)
      : didDonorPayProcessingFee
      ? refundTicketPriceAmount.times(cociCcProcessingFeeAsDecimal)
      : Big(0);
    const refundTotal = refundTicketPriceAmount.plus(
      refundProcessingFeeDonorAmount
    );

    return (
      <React.Fragment>
        <Modal
          isOpen={show && !showRefundConfirmationModal}
          className="center-modal-container"
        >
          <div className="modal-card card refund-modal large-modal">
            <p className="large-text fw-500">Refund</p>
            <p className="accent-text mb-24">Order number: {orderNumber}</p>
            <div>
              <Radio
                name="fullRefund"
                onChange={this.onChange}
                options={[
                  {
                    value: true,
                    display: "Full Refund",
                  },
                  {
                    value: false,
                    display: "Partial Refund",
                  },
                ]}
                value={fullRefund}
              />

              <div style={{ marginLeft: "29px" }}>
                <div className="flex flex-align-center">
                  <p className="small-text accent-text">Ticket Qty</p>
                  <input
                    type="number"
                    className="custom-input ml-16"
                    disabled={fullRefund}
                    max={quantity}
                    min="1"
                    onChange={(event) =>
                      this.onChange("refundQuantity", event.target.value)
                    }
                    value={refundQuantity}
                  />
                  {!fullRefund && (
                    <>
                      {didDonorPayProcessingFee && (
                        <span className="small-text accent-text ml-16">
                          {formatCurrency(
                            refundTicketPriceAmount,
                            currencyCode
                          )}{" "}
                          Ticket Total +{" "}
                          {formatCurrency(
                            refundProcessingFeeDonorAmount,
                            currencyCode
                          )}{" "}
                          CC Fee =
                        </span>
                      )}
                      <span className="ml-8">
                        {formatCurrency(refundTotal, currencyCode)}
                      </span>
                    </>
                  )}
                </div>
                {submitAttempted &&
                  !fullRefund &&
                  (!refundQuantity || refundQuantity > quantity) && (
                    <span className="error-text">
                      Please enter a valid refund quantity of up to {quantity}{" "}
                      {pluralizeText("ticket", quantity)}
                    </span>
                  )}
              </div>
            </div>
            {loading ? (
              <div className="modal-btns-loader">
                <Loader />
              </div>
            ) : (
              <div className="modal-btns">
                <button
                  className="btn link-text uppercase-text"
                  onClick={this.onClose}
                >
                  Cancel
                </button>
                <button
                  className="btn link-text uppercase-text ml-24"
                  onClick={this.onRefundOrder}
                >
                  Continue
                </button>

                {errorMessage && (
                  <span className="error-text">{errorMessage}</span>
                )}
              </div>
            )}
          </div>
        </Modal>

        <ConfirmationModal
          cancel={this.onClose}
          confirm={this.refundOrder}
          confirmText="Refund"
          errorMessage={errorMessage}
          loading={loading}
          message={
            <>
              {raffleIsPastDrawingDate ? (
                <p className="medium-text error-text">
                  Warning: the Grand Draw was already drawn.
                </p>
              ) : (
                ""
              )}
              Are you sure you want to refund{" "}
              {formatCurrency(refundTotal, currencyCode)} to {donorFirstName}{" "}
              {donorLastName}? This can't be undone.
            </>
          }
          show={showRefundConfirmationModal}
        />
      </React.Fragment>
    );
  }
}
