import React from "react";
import {
  formatCurrency,
  formatFullName,
  formatNumber,
  pluralizeText,
} from "../../../../lib";
import { RafflePaymentTypes } from "../../RaffleConsts";
import moment from "moment";
import { calculateRefundBreakdown } from "../../RaffleLogic";

const formatPaymentAmount = (
  ticketPriceAmount,
  quantity,
  processingFeeDonorAmount,
  currencyCode,
  className
) => (
  <>
    <p className={className}>
      {formatCurrency(Math.abs(ticketPriceAmount), currencyCode)}{" "}
      {quantity > 0 &&
        `(${formatNumber(quantity)} ${pluralizeText(
          "ticket",
          parseFloat(quantity)
        )})`}
    </p>
    {Math.abs(processingFeeDonorAmount) > 0 && (
      <p className={`${className ? className : "accent-text"}`}>
        + {formatCurrency(Math.abs(processingFeeDonorAmount), currencyCode)} CC
        Fee
      </p>
    )}
  </>
);

export default ({
  cociCcProcessingFeePercentage,
  enrollment,
  order,
  readOnlyAccess,
  refundOrder,
  resendOrderEmail,
  viewOrderDetails,
  usdTicketPrice,
  cadTicketPrice,
  gbpTicketPrice,
}) => {
  const {
    amount,
    association,
    associationDisplay,
    cashType,
    ccBrand,
    currencyCode,
    dateOrderedOn,
    donorEmail,
    donorFirstName,
    donorLastName,
    donorPhone,
    isPrivate,
    lastFour,
    orderNumber,
    paymentMethod,
    processingFeeDonorAmount,
    quantity,
    referrerName,
    refundAmount,
    sellerName,
    shliachFirstName,
    shliachLastName,
    singleTicketPrice,
    source,
    ticketPriceAmount,
  } = order;

  const orderRecordId = `order-record-${orderNumber}`;

  const {
    refundTicketPriceAmount,
    refundProcessingFeeDonorAmount,
    refundQuantity,
  } = calculateRefundBreakdown(
    refundAmount,
    cociCcProcessingFeePercentage,
    singleTicketPrice
  );

  return (
    <div
      className={`raffle-details-table-row raffle-details-order-${
        enrollment ? "by-enrollment-" : ""
      }table-row hover`}
      id={orderRecordId}
      onClick={viewOrderDetails}
    >
      <div>
        <p>{formatFullName(donorFirstName, donorLastName)}</p>
        <p className="accent-text">{donorEmail}</p>
        <p className="accent-text">{donorPhone}</p>
        <p className="accent-text">
          {association !== "None" ? associationDisplay : ""}
        </p>
      </div>
      <div>{orderNumber}</div>
      <div>
        {formatPaymentAmount(
          ticketPriceAmount,
          quantity,
          processingFeeDonorAmount,
          currencyCode
        )}
        {!!refundAmount &&
          formatPaymentAmount(
            refundTicketPriceAmount,
            refundQuantity,
            refundProcessingFeeDonorAmount,
            currencyCode,
            "refunded"
          )}
      </div>
      <div>
        <p>{moment(dateOrderedOn).format("MM/DD")}</p>
      </div>
      {enrollment ? (
        <div>{referrerName}</div>
      ) : (
        <div>
          <p>{sellerName}</p>
          <p className="accent-text">
            {formatFullName(shliachFirstName, shliachLastName)}
          </p>
        </div>
      )}
      <div>
        <p>
          {paymentMethod === RafflePaymentTypes.CreditCard
            ? `${ccBrand || "CC"} *${lastFour}`
            : cashType}
        </p>
        <p className="accent-text">{source}</p>
        {isPrivate && (
          <p className="accent-text fw-700 flex">
            <i className="material-icons medium-text mr-4">visibility_off</i>{" "}
            Private
          </p>
        )}
      </div>
      {!readOnlyAccess && !enrollment && (
        <div>
          {amount > 0 && (
            <p
              className="link-text uppercase-text xs-text fw-500"
              onClick={(event) => {
                event.stopPropagation();
                refundOrder();
              }}
              onMouseOver={(event) => {
                const record = event.target.closest(`#${orderRecordId}`);
                if (record) record.classList.remove("hover");
              }}
              onMouseLeave={(event) => {
                const record = event.target.closest(`#${orderRecordId}`);
                if (record) record.classList.add("hover");
              }}
              style={{ letterSpacing: "1px" }}
            >
              Refund
            </p>
          )}
          {!!refundAmount &&
            (!amount ? (
              <p className="error-text">Refunded</p>
            ) : (
              <p className="accent-text">Partially Refunded</p>
            ))}
        </div>
      )}
      {!readOnlyAccess && !refundAmount && (
        <div className="text-center">
          <i
            className="material-icons large-text link-text-secondary"
            onClick={(event) => {
              event.stopPropagation();
              resendOrderEmail();
            }}
          >
            emails
          </i>
        </div>
      )}
    </div>
  );
};
