import React from "react";
import AuthClient from "../../services/resources/AuthClient";
import { formatValuesForForm } from "../../lib";
import { Link } from "react-router";

import Loader from "./Loader";
import ChabadHouseOptions from "./ChabadHouse/ChabadHouseOptions";
import ChabadHouseForm from "./ChabadHouse/ChabadHouseFormContainer";
import SchoolForm from "./School/SchoolForm";

export class SchoolPageContainer extends React.Component {
  state = {
    campus: {},
    chabadHousesDropdownOpen: false,
    errorMessage: false,
    loading: "",
    saving: "",
    submitted: "",
    success: "",
    sys: {},
    width: "",
  };

  componentDidMount() {
    const { schoolID } = this.props.routeParams;
    if (schoolID) this.getProfileCampus(schoolID);
    this.getSystemSettings();
    this.setState({ width: window.innerWidth });
  }

  componentDidUpdate(prevProps) {
    const { schoolID, chabadHouseID } = this.props.routeParams;
    if (prevProps.routeParams.chabadHouseID && !chabadHouseID) {
      this.getProfileCampus(schoolID);
    }
  }

  getProfileCampus = async (id) => {
    this.setState({ loading: true });

    const { data } = await AuthClient.get(
      `/Campuses/${id}?includeInactive=true`
    ).catch((err) => {
      return {};
    });

    this.setState({
      campus: data || {},
      loading: false,
    });
  };

  getSystemSettings = async () => {
    let sys = {};
    await AuthClient.get(`/System`)
      .then((res) => (sys = res.data.payload))
      .catch((e) => console.log(e));
    this.setState({ sys: sys });
  };

  updateMenuLists = async (id) => {
    let errors = {};
    let data = this.state.campus;
    await AuthClient.get(`/Campuses/${id}`)
      .then((res) => {
        data.chabadHouses = res.data.chabadHouses;
      })
      .catch((e) => (errors.chabadHouses = true));
    this.setState({
      campus: {
        ...this.state.campus,
        chabadHouses: data.chabadHouses,
      },
    });
  };

  checkForErrors = (errors) => {
    errors = errors && Object.keys(errors);
    if (errors && errors.length > 0) {
      this.setState({ errorMessage: true });
    }
    return;
  };

  onSubmit = async (values, { resetForm }) => {
    this.setState({ saving: true });

    let { programLevels, ...campusValues } = values;
    //the program levels field handles both objects and strings...
    //TODO update program levels to handle just strings
    programLevels = programLevels.map((p) => p.value || p);
    campusValues.programLevels = programLevels;

    const { data, error } = await AuthClient.post(
      `/Campuses`,
      campusValues
    ).catch((error) => {
      setTimeout(() => {
        this.setState({ submitted: undefined });
      }, 3000);
      return { error };
    });
    if (error) {
      this.setState({
        submitted: false,
        submitError: error.response,
      });
      return;
    }

    this.setState({
      campus: data.payload,
      loading: false,
      submitted: true,
    });
    setTimeout(() => {
      this.setState({
        submitted: undefined,
      });
    }, 3000);

    setTimeout(() => {
      let updatedValues = formatValuesForForm({ ...this.state.campus });
      resetForm(updatedValues);
      this.setState({ saving: false });
    }, 1500);
    if (!this.props.routeParams.schoolID) {
      window.history.pushState(null, "", `/schools/${this.state.campus.id}`);
    }
  };

  render() {
    const { campus, loading, width, sys, chabadHousesDropdownOpen } =
      this.state;
    const { schoolID } = this.props.routeParams || campus.id;
    return loading ? (
      <div style={{ marginTop: "120px" }}>
        <div className="full-page-loader">
          <Loader />
        </div>
      </div>
    ) : (
      <React.Fragment>
        <div className="profile-page-subheader card inline-flex">
          <div className="container flex flex-justify-space flex-align-center">
            <ul className="profile-page-subheader-nav flex">
              <li
                onClick={() => {
                  if (width <= 968) {
                    this.setState({
                      chabadHousesDropdownOpen: false,
                    });
                  }
                }}
              >
                <Link
                  to={schoolID ? `/schools/${schoolID}` : `/schools/new`}
                  activeClassName="active-subheader-nav-link"
                >
                  School
                </Link>
              </li>
              <li
                onClick={() => {
                  if (width <= 968) {
                    this.setState({
                      chabadHousesDropdownOpen:
                        !this.state.chabadHousesDropdownOpen,
                    });
                  }
                }}
              >
                <p
                  className={
                    this.props.route.path.indexOf("/chabadHouse") > 1
                      ? "active-subheader-nav-link"
                      : !campus.id
                      ? "disabled-link"
                      : ""
                  }
                >
                  Chabad Houses
                </p>
                <div
                  className={
                    chabadHousesDropdownOpen
                      ? "profile-dropdown-box dropdown-box open"
                      : "profile-dropdown-box dropdown-box"
                  }
                >
                  <ul>
                    {campus.chabadHouses &&
                      campus.chabadHouses.map(
                        ({ chabadHouseID, chabadHouseName }) => (
                          <li key={chabadHouseID}>
                            <Link
                              to={`/schools/${schoolID}/chabadHouse/${chabadHouseID}?tab=general`}
                              activeClassName="active-subheader-nav-link"
                            >
                              {chabadHouseName}
                            </Link>
                          </li>
                        )
                      )}

                    <li>
                      <a
                        href={`/schools/${campus.id}/chabadHouseOptions`}
                        className="link-text flex flex-align-center mt-8"
                      >
                        <i className="material-icons profile-add-icon mr-8">
                          add_circle
                        </i>
                        Add Chabad House
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="profile-page page container">
          <div className="profile-cards-wrapper">
            {this.props.route.path.indexOf("chabadHouseOptions") > 1 ? (
              campus?.id ? (
                <ChabadHouseOptions campus={campus} campusID={schoolID} />
              ) : (
                ""
              )
            ) : this.props.route.path.indexOf("chabadHouse") > 1 ? (
              <ChabadHouseForm
                campus={campus}
                checkForErrors={this.checkForErrors}
                errorMessage={this.state.errorMessage}
                routeParams={this.props.routeParams}
                router={this.props.router}
                sys={sys}
                updateMenuLists={this.updateMenuLists}
              />
            ) : (
              <SchoolForm
                campus={campus}
                checkForErrors={this.checkForErrors}
                errorMessage={this.state.errorMessage}
                route={this.props.route}
                router={this.props.router}
                routeParams={this.props.routeParams}
                loading={loading}
                onSubmit={this.onSubmit}
                saving={this.state.saving}
                submitted={this.state.submitted}
                sys={sys}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
