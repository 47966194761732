import AuthClient from "./AuthClient";
import queryString from "query-string";

const EduApi = {
  getEduPrograms: async (cancelToken) => {
    const response = await AuthClient.get("EduPrograms", { cancelToken });
    return response.data.payload || [];
  },
  getEduProgram: async (cancelToken, eduProgramId, includeCounts) => {
    const response = await AuthClient.get(
      `EduPrograms/${eduProgramId}${
        includeCounts ? "?includeProgramCounts=true" : ""
      }`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  submitEduProgram: async (cancelToken, eduProgram) => {
    const response = await AuthClient.post("EduPrograms", eduProgram, {
      cancelToken,
    });
    return response.data.payload || {};
  },
  getEduProgramCourses: async (
    cancelToken,
    eduProgramId,
    page,
    results,
    sortByOption,
    filters = {},
    isExport
  ) => {
    const params = {
      isExport,
      page,
      results,
      sortByOption,
      category: filters.category,
      level: filters.level,
      text: filters.keyword,
    };

    const response = await AuthClient.get(
      `EduPrograms/${eduProgramId}/courses?${queryString.stringify(params)}`,
      { cancelToken }
    );
    return {
      courses: response.data.payload.results || [],
      totalCourses: response.data.payload.numberOfRows || 0,
    };
  },
  getEduProgramCourse: async (cancelToken, eduProgramId, courseId) => {
    const response = await AuthClient.get(
      `EduPrograms/${eduProgramId}/courses/${courseId}?includeInactive=true`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  submitEduProgramCourse: async (cancelToken, eduProgramId, course) => {
    const response = await AuthClient.post(
      `EduPrograms/${eduProgramId}/courses`,
      course,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getEduChabadHouses: async (
    cancelToken,
    eduProgramId,
    page,
    results,
    sortByOption,
    filters = {},
    isExport
  ) => {
    const params = {
      eduProgramId,
      includeInactive: true,
      isExport,
      page,
      results,
      sortByOption,
      subscriptionStatus: filters.subscriptionStatus,
      text: filters.keyword,
    };

    const response = await AuthClient.get(
      `EduChabadHouses?${queryString.stringify(params)}`,
      { cancelToken }
    );
    return {
      chabadHouses: response.data.payload.results || [],
      totalChabadHouses: response.data.payload.numberOfRows || 0,
    };
  },
  getEduChabadHouse: async (cancelToken, eduChabadHouseEnrollmentId) => {
    const response = await AuthClient.get(
      `EduChabadHouses/${eduChabadHouseEnrollmentId}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getEduChabadHouseCourses: async (
    cancelToken,
    eduChabadHouseEnrollmentId,
    page,
    results
  ) => {
    const params = {
      enrolledCoursesOnly: true,
      limitToLatestCourseSchedule: false,
      page,
      results,
      sortByOption: "date",
    };

    const response = await AuthClient.get(
      `EduChabadHouses/${eduChabadHouseEnrollmentId}/Courses?${queryString.stringify(
        params
      )}`,
      { cancelToken }
    );
    return {
      courses: response.data.payload.results || [],
      totalCourses: response.data.payload.numberOfRows || 0,
    };
  },
  submitEduChabadHouse: async (cancelToken, eduChabadHouse) => {
    const response = await AuthClient.post("EduChabadHouses", eduChabadHouse, {
      cancelToken,
    });
    return response.data.payload || {};
  },
  getEduChabadHousesBasic: async (cancelToken, eduProgramId) => {
    const response = await AuthClient.get(
      `EduChabadHouses/basic?eduProgramId=${eduProgramId}`,
      { cancelToken }
    );
    return response.data.payload || [];
  },
  getEduChabadHousesCampuses: async (cancelToken, eduProgramId) => {
    const response = await AuthClient.get(
      `EduChabadHouses/campuses?eduProgramId=${eduProgramId}`,
      { cancelToken }
    );
    return response.data.payload || [];
  },
  getEduChabadHousesShluchim: async (cancelToken, eduProgramId) => {
    const response = await AuthClient.get(
      `EduChabadHouses/shluchim?eduProgramId=${eduProgramId}`,
      { cancelToken }
    );
    return response.data.payload || [];
  },
  getEduStudents: async (
    cancelToken,
    eduProgramId,
    page,
    results,
    sortByOption,
    filters = {},
    isExport
  ) => {
    const params = {
      eduProgramId,
      isExport,
      page,
      results,
      sortByOption,
      campusId: filters.campusId,
      shliachId: filters.shliachId,
      studentEnrollmentStatus: filters.status,
      text: filters.keyword,
    };

    const response = await AuthClient.get(
      `EduStudents?${queryString.stringify(params)}`,
      { cancelToken }
    );
    return {
      students: response.data.payload.results || [],
      totalStudents: response.data.payload.numberOfRows || 0,
    };
  },
  getEduStudent: async (cancelToken, eduStudentEnrollmentId) => {
    const response = await AuthClient.get(
      `EduStudents/${eduStudentEnrollmentId}`,
      {
        cancelToken,
      }
    );
    return response.data.payload || {};
  },
  addEduStudent: async (cancelToken, postObj) => {
    await AuthClient.post(`EduStudents`, postObj, {
      cancelToken,
    });
  },
  getEduStudentEnrollments: async (cancelToken, studentId) => {
    const response = await AuthClient.get(
      `EduStudents/enrollments/${studentId}`,
      {
        cancelToken,
      }
    );
    return response.data.payload || [];
  },
  getEduStudentCourses: async (
    cancelToken,
    eduStudentEnrollmentId,
    page,
    results,
    sortByOption
  ) => {
    const params = {
      eduStudentEnrollmentId,
      enrolledCoursesOnly: true,
      page,
      results,
      sortByOption,
    };

    const response = await AuthClient.get(
      `EduStudents/courses?${queryString.stringify(params)}`,
      { cancelToken }
    );
    return {
      courses: response.data.payload.results || [],
      totalCourses: response.data.payload.numberOfRows || 0,
    };
  },
  getEduStudentAvailableCourses: async (cancelToken, id) => {
    const response = await AuthClient.get(
      `EduStudents/${id}/availableSchedules`,
      { cancelToken }
    );
    return response.data.payload || [];
  },
  addEduStudentToCourse: async (cancelToken, postObj) => {
    await AuthClient.post(`EduStudents/courseSchedules`, postObj, {
      cancelToken,
    });
  },
  removeEduStudentFromCourse: async (
    cancelToken,
    courseScheduleEnrollmentId
  ) => {
    await AuthClient.delete(
      `EduStudents/courseSchedules/${courseScheduleEnrollmentId}`,
      {
        cancelToken,
      }
    );
  },
  getEduProgramCourseSchedules: async (
    cancelToken,
    eduProgramId,
    page,
    results,
    sortByOption,
    filters = {},
    isExport
  ) => {
    const params = {
      isExport,
      page,
      results,
      sortByOption,
      campusId: filters.campusId,
      classDateFrom: filters.classDateFrom,
      classDateTo: filters.classDateTo,
      isCompleted: filters.isCompleted,
      isCurrent: filters.isCurrent,
      isUpcoming: filters.isUpcoming,
      semester: filters.semester,
      text: filters.keyword,
      year: filters.year,
    };

    const response = await AuthClient.get(
      `EduPrograms/${eduProgramId}/courseSchedules?${queryString.stringify(
        params
      )}`,
      { cancelToken }
    );
    return {
      courseSchedules: response.data.payload.results || [],
      totalCourseSchedules: response.data.payload.numberOfRows || 0,
      totalStudentsEnrolled: response.data.payload.numberOfStudents || 0,
    };
  },
  importSinaiScholarsData: async (
    cancelToken,
    importFile,
    importType,
    onUploadProgress
  ) => {
    const formData = new FormData();
    formData.append("file", importFile);

    const uploadUrl =
      importType === "Locations"
        ? "EduChabadHouses"
        : "EduStudents/courseSchedules";
    await AuthClient.post(
      `${uploadUrl}/bulkImport?programType=SinaiScholars`,
      formData,
      {
        cancelToken,
        "Content-Type": "multipart/form-data",
        onUploadProgress,
      }
    );
  },
  getEduProgramRewards: async (
    cancelToken,
    eduProgramId,
    page,
    results,
    sortByOption,
    filters = {},
    isExport
  ) => {
    const params = {
      includeUnavailable: true,
      isExport,
      page,
      results,
      sortByOption,
      text: filters.keyword,
    };

    const response = await AuthClient.get(
      `EduPrograms/${eduProgramId}/rewards?${queryString.stringify(params)}`,
      { cancelToken }
    );
    return {
      rewards: response.data.payload.results || [],
      totalRewards: response.data.payload.numberOfRows || 0,
    };
  },
  getEduProgramReward: async (cancelToken, eduProgramId, rewardId) => {
    const response = await AuthClient.get(
      `EduPrograms/${eduProgramId}/rewards/${rewardId}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  submitEduProgramReward: async (cancelToken, eduProgramId, reward) => {
    const response = await AuthClient.post(
      `EduPrograms/${eduProgramId}/rewards`,
      reward,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getEduProgramRewardRequests: async (
    cancelToken,
    eduProgramId,
    page,
    results,
    sortByOption,
    filters = {},
    isExport
  ) => {
    const params = {
      eduProgramId,
      isExport,
      page,
      results,
      sortByOption,
      eduStudentEnrollmentId: filters.eduStudentEnrollmentId,
      campusId: filters.campusId,
      shliachId: filters.shliachId,
      statuses: filters.statuses,
      rewardRequestType: filters.requestType,
      text: filters.keyword,
      requestedDateFrom: filters.requestedDateFrom,
      requestedDateTo: filters.requestedDateTo,
    };
    const response = await AuthClient.get(
      `EduPrograms/${eduProgramId}/rewardRequests?${queryString.stringify(
        params
      )}`,
      { cancelToken }
    );
    return {
      rewardRequests: response.data.payload.results || [],
      totalRewardRequests: response.data.payload.numberOfRows || 0,
    };
  },
  submitEduStudentRewardRequest: async (
    cancelToken,
    eduProgramId,
    rewardRequest
  ) => {
    const response = await AuthClient.post(
      `EduPrograms/${eduProgramId}/rewardRequests`,
      rewardRequest,
      { cancelToken }
    );
    return response.data.payload || {};
  },
};

export default EduApi;
