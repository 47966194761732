import React from "react";
import SelectSearch from "react-select";
import debounce from "lodash.debounce";
import { EduProgramTypes } from "../EduConsts";

export default class LocationsTableFilters extends React.PureComponent {
  state = {
    keyword: "",
    subscriptionStatus: null,
  };

  onChange = (name, value, debounce) => {
    this.setState(
      {
        [name]: value,
      },
      debounce ? this.onFilterDebounce : this.onFilter
    );
  };

  onFilter = () => {
    const { keyword, subscriptionStatus } = this.state;
    this.props.applyFilters({
      keyword,
      subscriptionStatus: subscriptionStatus?.value,
    });
  };

  onFilterDebounce = debounce(this.onFilter, 500);

  render() {
    const { keyword, subscriptionStatus } = this.state;
    const { eduProgramType } = this.props;

    return (
      <div className="courses-locations-table-filters flex mt-24 mb-24">
        <div className="search-input mr-24">
          <input
            type="text"
            placeholder="Search Shliach Name or Chabad House Name"
            name="keyword"
            onChange={(event) =>
              this.onChange(event.target.name, event.target.value, true)
            }
            value={keyword}
          />
          <i className="material-icons accent-text-secondary flex flex-align-center">
            search
          </i>
        </div>
        {eduProgramType === EduProgramTypes.JewishU && (
          <SelectSearch
            isClearable={true}
            onChange={(val) => this.onChange("subscriptionStatus", val)}
            options={[
              { value: "Active", label: "Active membership" },
              { value: "Expired", label: "Expired membership" },
            ]}
            placeholder="All Membership Statuses"
            style={{ height: "36px" }}
            value={subscriptionStatus}
          />
        )}
      </div>
    );
  }
}
